
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from '../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import {  startLoginEmailPassword } from '../../action/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeError } from '../../action/ui';
import './login.css'
import { Divider } from '@mui/material';



const theme = createTheme();

export default function SignIn() { 

  const dispatch = useDispatch();
  
const [ formValues, handleInputChange ] = useForm ({
  email: '',
  password: ''
})

const { email, password } = formValues ; 

const handleLogin = ( e ) => {
  e.preventDefault();
  dispatch(startLoginEmailPassword(email,password))
  
}
/*
const handleGoogleLogin = (e) => {
  e.preventDefault();
  dispatch( startGoogleLogin() )
}
*/
const {loading } = useSelector(state => state.ui)
const {msgError} = useSelector(state => state.ui)
const notify = (x) => {
  const options = {
    onClose: props => dispatch(removeError()),

    };
  toast(x, options)
  
  
};

return (
    <>
    {
    msgError?.code&&(  <div> {notify(msgError.code)} <ToastContainer limit={1} 
  
    />  </div> )
    }
        <Box
        className='boxContainer'>
          <div className='flex'>
          
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography sx={{marginTop:'10px'}} component="h2" variant="h6" className="mt-3">
            Reportes V2
          </Typography>
          
          </div>
          <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
            <TextField
            className="typeText"
              required
              fullWidth
              id="email"
              label="Email "
              name="email"
              autoComplete="off"
              onChange={handleInputChange}
              value={email}
              autoFocus
            />
            <TextField
            className="typePass"
              required
              fullWidth
              name="password"
              autoComplete="off"
              onChange={handleInputChange}
              value={password}
              label="Password"
              type="password"
              id="password"       
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              { loading ? ('Cargando...') : ('Acceso') }
            </Button>     
            </Box>
          </Box>
    </>
  );
}

