import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    
  </Box>
);

export default function BasicCard({data}) {

  const  {TotalAranceles, TotalContratos, TotalEmpresas, TotalEscalas } = data[0];

  return (
  <>
  <Box
 sx={{
  display: 'flex',
  flexDirection: 'row',
  p: 0.5,
  m: 0.5,
  
}}
  >
    <Card sx={{ minWidth: 275, maxWidth: 275, marginLeft:5}}>
      <CardContent
   
      >
        <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>
          Total Contratos Creados
        </Typography>
        <Typography variant="h5" component="div" >
          {bull}{bull} {bull} {TotalContratos}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
         
        </Typography>
        <Typography variant="body2">
          
          <br />
          {}
        </Typography>
      </CardContent>
      <CardActions>
        
      </CardActions>
    </Card>

    <Card sx={{ minWidth: 275, maxWidth: 275, marginLeft:5}}>
      <CardContent
   
      >
        <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>
          Total Escalas Creadas
        </Typography>
        <Typography variant="h5" component="div" >
          {bull}{bull} {bull} {TotalEscalas}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
         
        </Typography>
        <Typography variant="body2">
          
          <br />
          {}
        </Typography>
      </CardContent>
      <CardActions>
        
      </CardActions>
    </Card>

    <Card sx={{ minWidth: 275, maxWidth: 275, marginLeft:5}}>
      <CardContent
   
      >
        <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>
          Total Aranceles Creados
        </Typography>
        <Typography variant="h5" component="div" >
          {bull}{bull} {bull} {TotalAranceles}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
         
        </Typography>
        <Typography variant="body2">
          
          <br />
          {}
        </Typography>
      </CardContent>
      <CardActions>
        
      </CardActions>
    </Card>

    <Card sx={{ minWidth: 275, maxWidth: 275, marginLeft:5}}>
      <CardContent
   
      >
        <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>
          Total Empresas Creadas
        </Typography>
        <Typography variant="h5" component="div" >
          {bull}{bull} {bull} {TotalEmpresas}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
         
        </Typography>
        <Typography variant="body2">
          
          <br />
          {}
        </Typography>
      </CardContent>
      <CardActions>
        
      </CardActions>
    </Card>


    </Box>
    </>
  );
}