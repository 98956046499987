import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";

import { logoutAction } from "../../../action/auth";
import { useDispatch } from "react-redux";

export default function AccountMenu() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch( logoutAction() )
  }


  return (
    <React.Fragment>
 
        <Tooltip title="Account settings">
          <IconButton  onClick={handleClick} size="small" >
            <Avatar></Avatar>
          </IconButton>
        </Tooltip>
     
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 2,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              
              display: "block",
              position: "relative",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="p-2">
        <MenuItem>
          <Avatar /> Perfil
        </MenuItem>
       
        <Divider />
        <MenuItem
        onClick={handleLogout}
        >
          <ListItemIcon>
            <PersonAdd fontSize="small" />
         
          </ListItemIcon>
          Salir
        </MenuItem>
        </div>
      </Menu>
    </React.Fragment>
  );
}
