import React, { useState } from "react";
import MaterialTable from "@material-table/core";
import { columns  } from './SedesDefColumns';
import { downloadExcel } from "../../hooks/useExcelExport";

export const TableMaterial = React.memo(({resp}) => {
  let data = resp

  return (
    
    <div className="container-fluid">
    <MaterialTable  
            title="Listado de Sedes"
      columns={columns}
      components={{
        FilterRow: (rowProps) => {
                    const { columns, onFilterChanged } = rowProps;
          return (
            <>
              <tr>
                {columns.map((col) => {
                  
                  if (col.field) {
                    return (
                      <td>
                        <input
                        placeholder={''}
                        style={{width:'75%', marginTop:'15px',border:'2px solid #77ab59',marginBottom:'10px', borderRadius:'10px', textAlign:'center'}}
                          id={col.field}
                          onChange={(e) => {
                            onFilterChanged(col.tableData.id, e.target.value);
                            
                          }}
                        />
                      </td>
                    );
                  }
                })}
              </tr>
            </>
          );
        },
      }}
      data={data}
      options={{
        idSynonym: 'unique_parent_id',
                headerStyle: { position: 'sticky', top: 0 },
        maxBodyHeight: 'auto' ,
        columnsButton: true,
        headerStyle: { 
          position: 'sticky',
           top: 1,  
            backgroundColor:
             "#33ab9f",
          color: "#FFF", },
        paging: true,
        grouping: true,
        pageSize:100,
        pageSizeOptions: [15, 50, 100],
        padding:'dense',
        filtering:true,
        maxBodyHeight: '800px',
        fixedHeader:true,
        exportMenu: [
         
        {
          label: 'Bajar a Excel',
          exportFunc: () => downloadExcel(data, 'Sedes')
        }]
      }}  

      
    />

</div>

  );
});
