import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function SocioBasicModal({information}) {


   

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (information?.id){

    const {id, apellido, nombre} = information;
    //NDocu, Email

    return (
        <div>
          <Button onClick={handleOpen}>VER DETALLE</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Debito Relacionado
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Relacion: {apellido} {nombre} {id} 
              </Typography>
            </Box>
          </Modal>
        </div>
      );   
}else{
    return(
        <h6>Sin Informacion</h6>
            
        )
}


  
}