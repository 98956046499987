export const escalaColumns = [{  
    id: 1,
    DT_RowId: "1",
    title:'#ID',
    width:'8%',
    field: 'ID',
        cellStyle: {
        backgroundColor: '#010101',
        color: '#FFF',
        
      },
      headerStyle: {
        backgroundColor: '#ffc300',
        color:'#010101'
      }
    },
    {  
        id: 2,
        DT_RowId: "2",
    title:'Desc',
    field: 'DescEscala'
    },
    {  
        id: 3,
        DT_RowId: "3",
    title:'ValorTitular',
        field: 'Titular'
    },
    {  
        id: 3,
        DT_RowId: "4",
    title:'DtoLife',
        field: 'DtoLife'
    }
]
