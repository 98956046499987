
import React from 'react'
import { Alert } from '@mui/material';
import { columns } from './QueryResultColumnsDef';
import { downloadExcel } from '../../hooks/useExcelExport';
import MaterialTable from "@material-table/core";
import { postFullQuery } from '../../helpers/api_post_data';
import { useFetchPostMegaQuery } from '../../hooks/useFetchPostMegaQuery';

export const QueryResult = ({sedesData, query}) => {

    const {url, api_key} = postFullQuery;
    const {data, err, loading} = useFetchPostMegaQuery(url,api_key,{"A":sedesData, "B": query })
    if (data){
    return (
        <MaterialTable  
            title="Query Result"
            columns={columns}
            data={data}
            fixedHeader
            fixedHeaderScrollHeight="200px"
            options={{   
                columnsButton: true,
                filtering: false,
                cursor:'none',
                headerStyle: { 
                  position: 'sticky',
                   top: 1,  
                    backgroundColor:
                     "#01579b",
                  color: "#FFF", },
                maxBodyHeight: '650px' ,
                columnsHiddenInColumnsButton: true,
                grouping: true,
                padding:'dense',
                pageSize: 100,
                paging: true,
                fixedHeader:true,
                exportMenu: [   
                    {
                      label: 'Bajar a Excel',
                      exportFunc: () => downloadExcel(data, 'MegaQuery')
                    }            
                  ]}}
            />
    )
        }else if(loading){
            return(
            <h6>Cargando</h6>
            )
        }
        else if (err){
            return(
        <Alert severity="error">Error,query no se proceso</Alert>
        )
}
}
