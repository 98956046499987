
export  const columnsDebitH = [
    {  
id: 1,
DT_RowId: "1",
title:'Cuota',
field: 'Cuota',
},
{  
    id: 2,
    DT_RowId: "2",
title:'ContratoAuID',
field: 'ContratoAuID',
},
{  
    id: 3,
    DT_RowId: "3",
title:'PeriodoGenerado',
field: 'PeriodoGenerado',

},
{  
    id: 4,
    DT_RowId: "4",
title:'Tipo',
field: 'Tipo',
},
{  
    id: 7,
    DT_RowId: "7",
title:'Escala',
field: 'Escala'
},
{  
    id: 8,
    DT_RowId: "8",
title:'Modalidad',
field: 'Modalidad'
},
{  
    id: 9,
    DT_RowId: "9",
title:'CtoBase',
field: 'CtoBase'
},
{  
    id: 10,
    DT_RowId: "10",
title:'DtoValor',
field: 'DescuentoValor'
},
{  
    id: 11,
    DT_RowId: "11",
title:'DtoMotivo',
field: 'DescuentoMotivo'
},
{  
    id: 12,
    DT_RowId: "12",
title:'Promocion',
field: 'Promocion',
width:'100%'
},
{  
    id: 13,
    DT_RowId: "13",
title:'ValorCuota',
field: 'ValorCuota'
},
{  
    id: 14,
    DT_RowId: "14",
title:'PosicionRF',
field: 'PosicionRF'
},
]
