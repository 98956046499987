import React from 'react';
import ReactDOM from 'react-dom';
import { ReportesApp } from './ReportesApp';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/styles/divMainRoot.css'
import 'animate.css';

import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

const queryClient = new QueryClient();

ReactDOM.render(
  
  <QueryClientProvider client={queryClient}>
    <ReportesApp />
    </QueryClientProvider>,
  
  document.getElementById('root')
);




