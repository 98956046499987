import React from 'react'
import {  Card } from 'react-bootstrap'
import { Grid } from '@mui/material';

import SocioBasicModal from './SocioModalDebitH'
import { SocioModalAuditoria } from './SocioModalAuditoria';
import { ModalAccesoLog } from './SocioModalAccesoLog';
//width: '155px' ,height:'100px'
export const SociosColumnThree = ({debitHistory,auditoria, auditAccess}) => {

   if (debitHistory){

    return (
        <>
            <Grid container spacing={1} sx={{justifyContent:''}}>
             <Grid item  >
             <Card style={{ width: '155px' ,height:'100px', padding:0, marginTop:5, justifyContent:'center', alignContent:'center', textAlign:'center' }}>
                        <Card.Body>
                        <Card.Title className='text-center'>Historico Debitos</Card.Title>
                        <SocioBasicModal information={debitHistory} />
                        </Card.Body>
                    </Card>
                </Grid >    
                <Grid item  >
             <Card style={{ width: '155px' ,height:'100px', padding:0, marginTop:5, justifyContent:'center', alignContent:'center', textAlign:'center' }}>
                        <Card.Body>
                        <Card.Title className='text-center'> Auditoria</Card.Title>
                        <SocioModalAuditoria information={auditoria} />
                        </Card.Body>
                    </Card>
                </Grid >   
                <Grid item  >
             <Card style={{ width: '155px' ,height:'100px', padding:0, marginTop:5, justifyContent:'center', alignContent:'center', textAlign:'center' }}>
                        <Card.Body>
                        <Card.Title className='text-center'> Acceso Log</Card.Title>
                        <ModalAccesoLog information={auditAccess}/>
                        </Card.Body>
                    </Card>
                </Grid >          
           </Grid>
        </>
    )
    }else{
        return(
            <>
            </>
        )
    }
}
