import { formatDDMMYYYY, YYYYMM } from "../../../helpers/date_converter";

export  const comunsSocioCtaCte = [
    {  
id: 1,
DT_RowId: "1",
title:'Fecha',
field: 'Fecha',
filtering: true,
render: rowData => {
    return  formatDDMMYYYY(rowData.Fecha,'-')
  },
  headerStyle: {
  }
},
{  
    id: 2,
    DT_RowId: "2",
title:'Tipo',
field: 'Tipo',
width: 'auto'
},
{  
    id: 4,
    DT_RowId: "4",
title:'Detalle',
field: 'Detalle',
render: rowData => {
     if (rowData.Anulado){
         return `${rowData.Detalle}   Movimiento Anulado`;
     }else {
        return rowData.Detalle;
     }}},
{  
    id: 5,
    DT_RowId: "5",
title:'Cuota',
field: 'Cuota'
},
{  
    id: 6,
    DT_RowId: "6",
title:'Periodo',
field: 'Periodo',
render: rowData => {
    return  YYYYMM(rowData.Periodo,'-')
  },
  headerStyle: {
  }
},
{  
    id: 7,
    DT_RowId: "7",
title:'Importe',
field: 'Importe'
},
{  
    id: 8,
    DT_RowId: "8",
title:'Saldo',
field: 'Saldo'
},
{  
    id: 9,
    DT_RowId: "9",
title:'Usuario',
field: 'Usuario'
},
{  
    id: 10,
    DT_RowId: "10",
title:'FacturaElectronica',
field: 'FacutaElectronica'
}
]
