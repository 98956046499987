import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { useState } from 'react';
import { ActiveUsers } from './ActiveUsers';

export const AdminPanelScreen = () => {

const [panelView, setpanelView] = useState('')


    const render = () => {
   
        switch (panelView) {
            case 'ActiveUsers':
            return <ActiveUsers />
            default:
            return <></>
        }
    }

    

  return (
    <>  
             <Card sx={{ maxWidth: 250,
                  borderRadius: 16 
                 }}
                >
                    <CardActionArea
                     onClick={()=>{
                     
                       setpanelView('ActiveUsers')
                     }}
                    >              
                        <CardContent>
                        <Typography gutterBottom variant="subtitle" color={'primary'} component="div">
                            Listar Usuarios Activos
                        </Typography>               
                        </CardContent>
                        
                    </CardActionArea>

                  

                    </Card>

                    <hr/>

                    {render()}


    </>
  )
}
