
import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Box, Divider } from '@mui/material';
//import daxeImg from '../../../assets/daxe.jpg'
import { DaxeStartLoading } from './DaxeStartLoading';
import { DaxeListFiles } from './DaxeListFiles';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { clearComponent } from '../../../action/sideBarComponents';

export default function MultiActionAreaCard() {
  const [state, setState] = useState('Ready');
  const dispatch = useDispatch();

  const comp = useSelector(comp => comp.component)


  const [isloading, setisLoading] = useState(false);

  useEffect(() => {

    

    if ( comp?.display === 'ListDaxeComponent'){
      setState('ListDaxe');
      setisLoading(false);
    }

    if (comp.loading) {
      setisLoading(true)
    } else {
      setisLoading(false)
    }


  }, [comp, setisLoading, setState]);

useEffect(() => {
  setState('ListDaxe');
}, [])


  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row',
       justifyContent: 'center', padding: '15px', marginTop: '15px', marginBottom: '15px' }}
       className='animate__animated animate__headShake'
       >
        <Card
          sx={
            {
              maxWidth: 185,
              maxHeight: 85,
              marginRight: '15%'
            }
          }
          onClick={
            () => {
              dispatch( clearComponent() )
              if (state && !isloading) {
                setState('NewDaxe');
              } else {
                Swal.fire({
                  icon: 'error',
                  title: '..',
                  text: 'Archivo en proceso de carga',

                })
              }
            }
          }
        >
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Solicitar un Nuevo Daxe
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Divider orientation="vertical" flexItem />
        <div className='mt-5'></div>
        <Card
          sx={
            {
              maxWidth: 185,
              maxHeight: 85
            }
          }
          onClick={
            () => {
              if (state && !isloading) {
                setState('ListDaxe')
              } else {
                Swal.fire({
                  icon: 'error',
                  title: '..',
                  text: 'Archivo en proceso de carga',

                })
              }
            }
          }
        >
          <CardActionArea >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Listar Archivos
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>



      </Box>

      <Divider style={{ marginBottom: '15px', height: '2px', color: '#221266' }}></Divider>

      {
        state === 'NewDaxe' && (<DaxeStartLoading />)
      }
      {
        state === 'ListDaxe' && (<DaxeListFiles />)
      }

    </>
  );
}
