import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { ShowPreciosSideIcons } from './ShowPreciosSideIcons';
import { ShowSedesSideIcons } from './ShowSedesSideIcons';
import { ShowSociosSideIcons } from './ShowSociosSideIcons';

export const DefaultSideList = () => {

    const { display } = useSelector(state => state.sidebar);

    const sideIconsSwitch = ( display ) => {
        switch (display) {
            case 'Sedes':  return (<ShowSedesSideIcons />)
            case 'Precios':  return (<ShowPreciosSideIcons />)
            case 'Socios':  return (<ShowSociosSideIcons />)
            default: return (<></>)             
          }} ;
   
        if ( display) {
            return ( <> {sideIconsSwitch(display)} </> )
        }else{
            return (<></>)
        }
      
    }


