import React, { useEffect, useState } from 'react'
import { CtaCteTableMaterial } from '../../MaterialTable/CtaCteTableMaterial';

import { comunsSocioCtaCte } from './SociosCtaCteColumns';

export const SociosCtaCte = ({information}) => {
  
  

    const [dataComputed, setDataComputed] = useState(false)

  

    useEffect(() => {
        let startSaldo=0;

        if (!dataComputed){
            for (const x of information) {
               
                if ( !x.Anulado && ( x.Haber  ) && !(x.TipoLetter==='N')  ){
                    x.Saldo=startSaldo-x.Importe
                    startSaldo-=x.Importe   
                }else if ( !x.Anulado &&  x.Debe  && !(x.TipoLetter==='N') ){
                    x.Saldo=startSaldo+x.Importe  
                    startSaldo=x.Saldo          
                }else if ( (x.TipoLetter==='N') )   {
                    x.Saldo=startSaldo
                }
            }
        }   
        setDataComputed(true)

    }, [setDataComputed, dataComputed, information])
    
if ( dataComputed) {
    
    return (
        <div>
            
            <CtaCteTableMaterial data={information} columns={comunsSocioCtaCte} title={'Cuenta Corriente'}  ></CtaCteTableMaterial>
        </div>
    )
}else{
    return(
        <>
        </>
    )
}
}
