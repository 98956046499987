import { types } from "../types/types"


export const setSedeSelected = (data) => ({
    type: types.setSedeSelected,
    payload: data

})

export const populateSedesList = (data) => ({
    type: types.populateSedeList,
    payload: data
})

export const setChange = () => ({
    type: types.setChange,
   
})

export const setFinishChange = () => ({
    type: types.setFinishChange,
   
})

export const cleanSedeSelected = () => ({
    type: types.sedeSelectedClean,
   
})
