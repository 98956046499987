export const contratoColumns = [{  
    id: 1,
    DT_RowId: "1",
    title:'# ID',
    width:'8%',
    field: 'Contrato',
        cellStyle: {
        backgroundColor: '#010101',
        color: '#FFF',
        
      },
      headerStyle: {
        backgroundColor: '#ffc300',
        color:'#010101'
      }
    },
    {  
        id: 2,
        DT_RowId: "2",
    title:'Desc',
    field: 'NombreContrato'
    },
    {  
        id: 3,
        DT_RowId: "3",
    title:'Escala',
        field: 'id_escalas_ca'
    },
    {  
        id: 4,
        DT_RowId: "4",
    title:'Valor',
     field: 'valor'
    },
     {  
        id: 3,
        DT_RowId: "3",
    title:'ArancelGed',
        field: 'ValorArancel'
    
     


    
}]