import React, { useEffect, useState } from 'react'
import {  useSelector } from 'react-redux';

import { ErrorAlert } from '../../GlobalMessages/Error'
import { SocioSearchApi } from '../SocioSearchApi';


export const SocioSearch =  React.memo(( {sedeData}) => {


    const state = useSelector(state => state.socios)
    const {DNI} = state
    const [dniState, setDni] = useState(null)

        useEffect(() => {   
          
            setDni(DNI)         
        
        }, [])
    
       

    if ( dniState ){
        return (
            <>
               <SocioSearchApi information={dniState} sedeData={sedeData} />
            </>
        )
    }else{
        return(
                <>
                <ErrorAlert message={'Ingreso invalido.'}></ErrorAlert>
                </>
        )
    }
})
