export  const columns = [
    {  
id: 1,
DT_RowId: "1",
title:'#',
field: 'IDSede',
width:'5%',
cellStyle: {
    backgroundColor: '#039be5',
    color: '#FFF'
  },
  headerStyle: {
    backgroundColor: '#039be5',
  }
},
{  
    id: 2,
    DT_RowId: "2",
title:'Res',
field: 'res'
},
{  
    id: 3,
    DT_RowId: "3",
title:'LineasAfectadas',
width:'10%',
field: 'rowsAffected'
},
{  
    id: 4,
    DT_RowId: "4",
title:'Estado',
width:'10%',
field: 'status'
}]

