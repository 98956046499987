import React from 'react'
import { QueryResult } from './QueryResult'

export const QuerySendToBackend = (recibed) => {
    
    const {dataResult} = recibed;
    const {data:dataRecibed, query} = dataResult;
    let onlyProclub = dataRecibed.data.filter((x)=>Number(x.System)===1)
    
    if ( dataRecibed && query){
        return(
            <QueryResult sedesData={onlyProclub} query={query}></QueryResult>
        )
    }else{
        return(<>
                <h6>Error</h6>
            </>)
        
    }
   

   /*

if (dataRecibed){
    return (
        <>
        <div className="">
            <QueryResult datos={dataRecibed}></QueryResult>
        </div>
        </>
    )
}else if (
(loading)
){
    return (
      
        <>
            <FillingBottle onClick={() => alert("Para un poco Brother, esta cargando")} key="key" />    
        </>
        )

}else if (error){
    return(
        <>
        <Alert severity="error">Error al procesar el query contra el backend, Contacte con su administrador</Alert>
        </>
    )

}
*/
}