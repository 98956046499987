import moment from 'moment';
import 'moment/locale/es';

moment.updateLocale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('._'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

export const formatDDMMYYYY = (a, b) => {
    let dataToJsDate = new Date(a)
    let jsDateMoreOneDay = moment(dataToJsDate).utc();
    return moment(jsDateMoreOneDay).format(`DD${b}MM${b}YYYY`)
};

export const formatYYYYMMDD = (a, b) => {
    let dataToJsDate = new Date(a)
    let jsDateMoreOneDay = moment(dataToJsDate).utc();
    return moment(jsDateMoreOneDay).format(`YYYY${b}MM${b}DD`)
};

export const formatDDMMYYYYMMHH = (a, b, c) => {
    let dataToJsDate = new Date(a)
    let jsDateMoreOneDay = moment(dataToJsDate);
    return moment(jsDateMoreOneDay).format(`DD${b}MM${b}YYYY HH:mm`)
};



export const formatMMYYYY = (a, b) => {
    let dataToJsDate = new Date(a)
    let jsDateMoreOneDay = moment(dataToJsDate).utc();
    return moment(jsDateMoreOneDay).format(`MMM${b}YYYY`)
};

export const YYYYMM = (a, b, c = 0) => {
    let dataToJsDate = new Date(a)
    let jsDateMoreOneDay = moment(dataToJsDate).utc();
    if (!c) {
        return moment(jsDateMoreOneDay).format(`YYYY${b}MM`)
    } else {
        return moment(jsDateMoreOneDay).add(c, 'month').format(`YYYY${b}MMMM`)
    };
};

export const actualPeriod = () => {
    let dataToJsDate = new Date()
    let jsDateFormatted = moment(dataToJsDate).utc();
    let jsDateFormattedByPeriod = jsDateFormatted.format(`YYYY/MM`)
    return jsDateFormattedByPeriod;

};

export const isSuspended = (start, end) => {

    let dataToJsDate = new Date()

    let actually= moment(dataToJsDate).utc()

    if (start && end && actually) {
        let startDate = moment(start).utc();
        let endDate = moment(end).utc();
        let actuallyDate = moment(actually).utc();


        let starFormatted = startDate.format(`YYYY/MM`);
        let endFormatted = endDate.format(`YYYY/MM`);
        let actuallyFormatted = actuallyDate.format(`YYYY/MM`);

        return (actuallyFormatted >= starFormatted && actuallyFormatted <= endFormatted)

    } else {
        return false;
    };

};

export const validityCheck = (validity) => {

    let dataToJsDate = new Date()
    let jsDateFormatted = moment(dataToJsDate).utc();
    let validityFormatted = moment(validity).utc();
    
    return   ( moment(validityFormatted).isAfter(jsDateFormatted) )

}


