import React from 'react'
import { Alert } from '@mui/material';

export const ErrorAlert = ({message}) => {

    

    return (
        <div>
             <Alert severity="error"> {message} </Alert>
        </div>
    )
}

