import { Alert, Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PacmanBigoton from '../../../../assets/skaterguy.gif'
import { fetchRunBackup } from "../../../../helpers/api_data";

import { HeadersServerSedes } from "../../../../helpers/HeadersServerSedes";
import { ErrorAlert } from '../../../GlobalMessages/Error';
import { InfoAlert } from '../../../GlobalMessages/Info';
import { InfoJumpLine } from '../../../GlobalMessages/InfoJumpLine';
import { TrnDataScreen } from '../trnDataComponent/TrnDataScreen';
export const ProccessComponent = ({ action, sedeConn }) => {

  const [data, setData] = useState({
    data:null,
    error:null,
    loading:true
  });

      useEffect(() => {
    setData({  
       data:null,
      error:null,
      loading:true})
    async function fetchData() {
      if ( action  ){
        try {
          const response = await fetch(`${fetchRunBackup.url}?sede=${sedeConn.IDSede}&&action=${action}`,{
            headers:HeadersServerSedes
          });
          const json = await response.json();
  
              if ( json.error){
                setData({data:null, loading:false, error:json.data });
              }else{
                setData({data:json.data, loading:false,error:null});
            
              }
          
        } catch (error) {
          setData({data:null, loading:false, error:error});
        }
      }
   

    }
    fetchData();

    return () => {
      setData({  
        data:null,
       error:null,
       loading:true})
    }

  }, [sedeConn, action]);

if ( data.loading ){
  return(<>
    <img width='200px' alt='pacmanCat' src={PacmanBigoton}></img> 
  </>)
}else if ( data.error){
  return(<>
    <ErrorAlert message={data.error}/>
    </>)
}else if(data.data){
 
  if ( ( data.data[0]?.trn_name || data.data?.trnName )  ) {
    return(
      <TrnDataScreen trnInfo = {data.data} />
    )
  }else{
    return(
    <>
   <Alert severity="success">  <InfoJumpLine message={data.data}/></Alert>
    </>
  )}
  
}else{
  return(
    <>
    </>
  )
}


}
