
export class CreateSocioStatus {

    constructor(validation, activeDebit, susp) {

        if (activeDebit[0]?.Alta) {
            activeDebit = true
        } else {
            activeDebit = false
        }
        this._validation = validation;
        this._activeDebit = activeDebit;
        this._susp = susp;
    }
    returnData() {
        return {
            "validation": this._validation,
            "activeDebit": this._activeDebit,
            "susp": this._susp,
        }
    }

    returnStatus() {
        // IN THIS STATE SOCIO HAS A VALIDITY BUT IS NOT SUSPENDED OR WITH ACTIVE DEBIT
        if (this._validation && !this._activeDebit && !this._susp) {
            //YELLOW
            return {
                code: 0,
                detail: 'Acceso Permitido por vigencia restante.Sin DA',
                color: '#cddc39'
            }
            // THIS ISTATE IS AN ERROR, PLEASE REPORT; NO VALIDITY BUT ACTIVE DEBIT ; NO SUSPENDED
        } else if (!this._validation && this._activeDebit && !this._susp) {
            //ORANGE
            return {
                code: 99,
                detail: 'ERROR',
                color: '#b26500'
            }

            //THIS IS A NORMAL STATUS
        } else if (this._validation && this._activeDebit && !this._susp) {
            //GREEN
            return {
                code: 1,
                detail: 'Socio Activo',
                color: '#00695f'
            }
            //SUSPENDED
        } else if (this._validation && this._activeDebit && this._susp) {
            return {
                code: 1,
                detail: 'Futura Suspensión, Vigencia Restante',
                color: '#BCE46C'
            }
            //INACTIVE
        } else if (!this._validation && !this._activeDebit) {
            return {
                code: 1,
                detail: 'Socio Inactivo',
                color: '#b22a00'
            }

        }else if (!this._validation && this._activeDebit && this._susp){
            return {
                code: 1,
                detail: 'Suspendido',
                color: '#8ab200'
            }
        }
    }
}

