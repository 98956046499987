

export  const columns = [
    {  
id: 1,
DT_RowId: "1",
title:'#',
filtering:false,
field: 'IDSede',
  width:'2px'
},


{  
    id: 2,
    DT_RowId: "2",
title:'Sede',
field: 'Sede',
filtering: true
},
{  
    id: 3,
    DT_RowId: "3",
title:'Cat',
filtering:false,
field: 'Categoria'
},
{  
    id: 4,
    DT_RowId: "4",
title:'GADM',
filtering:false,
field: 'GrupoADM'
},
{  
    id: 5,
    DT_RowId: "5",
title:'TG',
filtering:false,
field: 'UsaTG'
},
{
    id: 6,
    DT_RowId: "6",  
title:'Mostrador',
filtering:false,
field: 'FiscalMostrador'
},
{  
    id: 7,
    DT_RowId: "7",
title:'Debitos',
filtering:false,
field: 'FiscalDebitos'
},
{  
    id: 8,
    DT_RowId: "8",
title:'Arancelada',
filtering:false,
field: 'Arancelada'
},
{  
    id: 9,
    DT_RowId: "9",
title:'Sistema',
filtering:false,
field: 'Sistema'
}]

    
    
    
    
