import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';


export const useFetch =  ( url,api_key ) => {  

    const token  = sessionStorage.getItem('token-rp');

    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, loading: true, error: null, errorDetail:null });
   
    useEffect( () => {
        return () => {
            isMounted.current = false;
        }
    }, [])
    useEffect( () => {
        setState({ data: null, loading: true, error: null });

         fetch( url,{ method: 'GET',
         headers: {'apikey': api_key,
            'x-token':token,
            'Content-Type': 'application/json'

        }})
            .then( resp => resp.json() )
            .then( data => {              
                if ( isMounted.current ) {
                    setState({
                        loading: false,
                        error: null,
                        data: data
                    });
                }
            }).catch( e => {
                    
                setState({
                    loading: false,
                    error: true,
                    data: null,                 
                    errorDetail:e
                });;
              });          
    },[url,api_key])

       
    return state;
}

useFetch.propTypes = {
    url: PropTypes.string.isRequired,
    api_key: PropTypes.string.isRequired,
}

