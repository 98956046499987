import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { ShowSociosInLocal } from './ShowSociosInLocal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CachedIcon from '@mui/icons-material/Cached';

export const ShowSociosSideIcons = () => {

    const [localData, setLocalData] = useState(null);
    const [showData, setShowData] = useState(false);

    const getDataFromLocal = () => {
        try {
            let localStorageArr = []
            let localStorageKeys = Object.keys(localStorage)
            for (let i = 0; i < localStorageKeys.length; i++) {
                const keysIterated = localStorageKeys[i];
                let data = localStorage.getItem(keysIterated)
                let dataParsed = JSON.parse(data)
                let o = {
                    IDSede: dataParsed.IDSede,
                    Codigo: dataParsed.Codigo
                }
                localStorageArr.push(o)
            }
            return localStorageArr;
        } catch (error) {
            return null;
        }
       
    }

    useEffect(() => {
        
        if (getDataFromLocal()==!null) {
            setLocalData(getDataFromLocal()); 
        }
        
    }, [setLocalData]);

    return (<Box sx={{}}>
         <CachedIcon style={{paddin:10, width:'100%', color:'orange', border:'0.1px solid', marginTop:'10px', cursor:'pointer'}}
                    onClick={()=>{
                        setLocalData(getDataFromLocal())
                        setShowData(false)
                        setTimeout(() => {
                            setShowData(true)
                        }, 150);
                    }} 
                >  </CachedIcon>
        <List>
            <ListItem button key='swLocal'
                onClick={() => { 
                    if (!showData){
                        setLocalData(getDataFromLocal())
                        setShowData(true)
                    }else{
                        setShowData(false)
                    }
                 }}>
                <ListItemIcon>
                    <KeyboardArrowDownIcon style={{paddin:0, marginLeft:'19%', color:'green'}}/>
                </ListItemIcon>
                <ListItemText primary='Recientes' />
               
            </ListItem>
           
        </List>

        {showData&&(<ShowSociosInLocal data={localData}/>)}
        <ListItem button key='asdasd '
                onClick={() => { 
                   localStorage.clear();
                   setShowData(false)
                 }}>
                <ListItemIcon style={{paddin:0, marginLeft:'7%', color:'red'}}>
                    <DeleteForeverIcon />
                </ListItemIcon>
                <ListItemText primary='Vaciar Lista' />
            </ListItem>

    </Box>
    )
};
