
import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { ErrorAlert } from '../GlobalMessages/Error'
import { SociosDatosPrimary } from './SociosDatosPrimary'
import { InfoAlert } from '../GlobalMessages/Info'
import { SedeListPick } from './SedeListPick'
import { Grid } from '@mui/material'


export const SedeSelector = (data) => {
    //02


     
    const state = useSelector(state => state.socios)
    const [searchSocio, setSearchSocio] = useState(false)
   
    const {IDSede, Codigo, DNS, Port, System} = state;

    let fullListProclub;

    useEffect(() => {     
        if  (IDSede && Codigo) {
            setSearchSocio(true)
        }else{       
            setSearchSocio(false)
            
        }

    }, [setSearchSocio, state, Codigo, IDSede])

   const {fullList} = data
   if (fullList){
    fullListProclub = fullList.filter(x=>x.System===1)
   }
   
    if ( fullListProclub ){
        return (
            <>
            <div>        
                    <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"      
                    >
                    <Grid item xs={3}>
                    <SedeListPick data={fullListProclub}/>
                    </Grid>   
                    
                    </Grid>           
            </div>
        {searchSocio?(        
                     <SociosDatosPrimary
                         IDSede={IDSede} 
                         Codigo={Codigo}
                         DNS=   {DNS}
                         Port=  {Port}
                         System={System}       
                         />
                         )
                         :
                         (  
                        <div className='animate__animated text-center animate__fadeIn slow'>
                        <InfoAlert  message='Ingresar Codigo de Socio'></InfoAlert>
                        </div>
                        )
                    }    
            </>
        )
    }else{
        return(
           <>
           <ErrorAlert message={'Error al cargar Sedes'}></ErrorAlert>
           </>
        )
    }
    
}
