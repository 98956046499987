import React, { useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter } from "react-router-dom";
import SignIn from '../components/login/LoginScreen';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import ResponsiveDrawer from '../components/ui/AppInterface';
import { login, readPermissions } from '../action/auth';
import { useDispatch } from 'react-redux';
import { LoadingScreen } from './LoadingScreen';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { useFetch } from '../hooks/useFetch';
import {fetchSedesParams} from '../helpers/api_data'
import { populateSedesList } from '../action/sedes';
import { getUserPermissions } from '../firebase/getUserPermissions';
import { getToken } from '../firebase/getToken';

export const AppRouter = () => {

    const dispatch = useDispatch()
 
    const [checking, setCheking] = useState(true);  
    const [isLoggedIn, setisLoggedIn] = useState(false)
    const {url, api_key} = fetchSedesParams;
    const {data} = useFetch(url, api_key)
    
    if (data){    
        dispatch(populateSedesList(data))
    }
    
    useEffect(() => {         
        const auth = getAuth();
        onAuthStateChanged(auth, (user) =>{          
                if ( user?.uid ){
                    dispatch(login(user.uid,user.email));
                    user.getIdToken().then(token => { 
                        let existToken = sessionStorage.getItem('token-rp');
                        existToken && sessionStorage.removeItem('token-rp');
                        sessionStorage.setItem('token-rp', token)
                     })
                    getUserPermissions()             
                    .then(res => {
                    res.forEach(docs=>{
                        let userInformation =  docs.data();
                        if (userInformation.uid===user.uid){
                            dispatch(readPermissions(userInformation.accessItems));
                        }
                    })})
                    setisLoggedIn(true);
                }else{
                    setisLoggedIn(false);
                }
                setCheking(false);
        })
    
    }, [dispatch, setCheking])


    if ( checking ) {return(<LoadingScreen/>)}
    return (
        <BrowserRouter>
        <Routes>
            <Route
                path="/login"
                element={
                    <PublicRoute isAuth={isLoggedIn}>
                        <SignIn />
                    </PublicRoute>
                }
            />
            <Route
                path="/*"
                element={
                    <PrivateRoute isAuth={isLoggedIn}>
                        <ResponsiveDrawer />
                    </PrivateRoute>
                }
            />
        </Routes>
    </BrowserRouter> 
    )
}
