import { Button } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { fetchContratosData, fetchEscalaData } from '../../../../helpers/api_data';
import { useFetch } from '../../../../hooks/useFetch'
import { TableMaterial } from '../../../MaterialTable/GlobalTableMaterial';
import { escalaColumns } from './escalaColumns';


export const BuscadorEscala = ({ apiParams }) => {

    const { value, tipe } = apiParams;

    const { data, loading, error } = useFetch(`${fetchEscalaData.url}?${tipe}=${value}`, apiParams);



    const rowPanelTreg = ({ rowData }) => {
        let arr = [];

        let newOjb = Object.entries(rowData).map(([key, value]) => {
            if (typeof (value) !== 'object') {
                arr.push({ [key]: (typeof (value) === 'boolean' ? "Si" : value) })
            }
        });
        return (
            <div
                style={{
                    fontSize: 20,
                    textAlign: "left",
                    height: '100%',
                }}
            >
                <ul>
                    <li>

                        {
                            arr.map((data, index) => {

                                let objAttribute = Object.keys(data)
                                return (<li key={index}> {objAttribute} = {JSON.stringify(rowData[objAttribute])} </li>)
                            })
                        }
                    </li>
                </ul>
            </div>
        )
    }

    if ( data?.length===0) {
            return(<>
            <h6>Sin registros</h6>
            </>)
    }else if (data) {
        
        return (
            <div>
                 <Button   disabled={true} > X </Button>
                <TableMaterial
                    title={"Escalas"}
                    data={data}
                    columns={escalaColumns}
                    maxBodyHeight={'auto'}
                    rowPanel={rowPanelTreg}
                />


            </div>
        )
    } else if (loading) {
        return (
            <div>
                <h1>Cargando...</h1>
            </div>
        )
    } else {
        return (
            <div>
                <h1></h1>
            </div>
        )
    }
}
