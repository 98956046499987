import React from 'react'
import { TableMaterial } from '../../MaterialTable/GlobalTableMaterial'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import { Box } from '@mui/material';
import { formatDDMMYYYYMMHH } from '../../../helpers/date_converter';


export const SocioModalAuditoria = ({information}) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


 
   const columns = [
        {  
    id: 1,
    DT_RowId: "1",
    title:'#',
    field: 'Row',
    filtering: false,
    width:33,
      headerStyle: {
       
      }
    },
    {  
        id: 2,
        DT_RowId: "2",
        title:'Permiso',
        field: 'Permiso',
        filtering: false,
        width:'auto',
          headerStyle: {
           
          }
        
        }
        ,
    {  
        id: 3,
        DT_RowId: "3",
        title:'Descripcion',
        field: 'Descripcion',
        filtering: false,
        width:'auto',
          headerStyle: {
           
          }
        
        }
        ,
        {  
            id: 4,
            DT_RowId: "4",
            title:'Obs',
            field: 'Observaciones',
            filtering: false,
            width:'auto',
              headerStyle: {
               
              }
            
            }
            ,
        {  
            id: 6,
            DT_RowId: "6",
            title:'Fecha y Hora',
            field: 'FechaYHora',
            filtering: false,
            width:'auto',
            render: rowData => {
                return  formatDDMMYYYYMMHH(rowData.FechaYHora,'-')
              },
              headerStyle: {
               
              }
            
            },
            {  
                id: 7,
                DT_RowId: "7",
                title:'ItemDetalle',
                field: 'ItemDetalle',
                
                filtering: false,
                width:'auto',
                  headerStyle: {
                   
                  }
                
                },
                {  
                    id: 8,
                    DT_RowId: "8",
                    title:'Operador',
                    field: 'Operador',
                    filtering: false,
                    width:'auto',
                      headerStyle: {
                       
                      }             
                    }
    ]

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        height: 'auto',
        bgcolor: 'background.paper',
        borderRadius: 10,
        border: 'radius 0px solid #000',
        boxShadow: 24,
        p: 4,
      };
      
   

    return (
        <div>
          <Button onClick={handleOpen}>Ver Detalle</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Auditoria
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div style={{ height: '100%', width: '100%' }}>
              <TableMaterial data={information} columns={columns} title={'Auditoria'} ></TableMaterial>         
             </div>
              </Typography>
            </Box>
          </Modal>
        </div>
      );     
}
