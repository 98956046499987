import { types } from "../types/types"


export const sidebarReducer = ( state = {display:'ShowDefault'}, action ) => {

    switch ( action.type ) {
        case types.sidebarDefault:
            return {
         display: 'Default'
            }
        case types.sidebarPrecios:
        return {
            display: 'Precios'
        }

        case types.sidebarSedes:
        return {
            display: 'Sedes'
        }

        case types.sidebarSocios:
            return {
                display: 'Socios'
            }

        default:
            return state;
    }

}