import { ReportSharp } from "@material-ui/icons";
import { TwentyOneMpSharp } from "@mui/icons-material";
import { types } from "../types/types";

const initialState = {
    display: null,
    mainComponent: null,
    loading: false
}

export const componentReducer = ( state = initialState, action ) => {

   
    switch ( action.type ) {
        case types.setListComponent:
            return { 
                display: 'List',
                mainComponent: action.payload,
                loading: false
        }
        case types.setPreciosDashboard:
            return { 
                display: 'DashBoard',
                mainComponent: action.payload,
                loading: false
        }
        case types.setAumentoBase:
            return { 
                display: 'AumentoBase',
                mainComponent: action.payload,
                loading: false
        }
        case types.setBuscador:
            return { 
                display: 'Buscador',
                mainComponent: action.payload,
                loading: false
        }
        case types.setTRN:
            return { 
                display: 'TRN',
                mainComponent: action.payload,
                loading: false
        }
        case types.setListDaxe:
            return { 
                display: 'ListDaxeComponent',
                mainComponent: 'Reports',
                loading: false
            }

            case types.addsede:
                return { 
                    display: 'AddSede',
                    mainComponent: 'Sedes',
                }

                case types.sedes:
                    return { 
                        display: 'Sedes',
                        mainComponent: 'Sedes',
                    }

        case types.setLoad:
            return { 
                ...state, loading:true
        }

        case types.unsetLoad:
            return { 
                ...state, loading:false
        }

        case types.clearComponent:
            return{ initialState }
      
        default:
            return state;
    }

}