import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

  
export const TrnDataScreen = ({trnInfo:data}) => {
    let isTrnLoad = false;
        if (data?.rowsAfter){
            data  = [data];
            isTrnLoad = true;
        }
        if (!isTrnLoad){
            return (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="right">Transaction Name</TableCell>
                        <TableCell align="right">Transaction File Date</TableCell>
                        <TableCell align="right">Date Processed</TableCell>
                        <TableCell align="right">Transaction Type Load</TableCell>
                        <TableCell align="right">Execution Time (Seconds)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="right">{row.trn_name}</TableCell>
                          <TableCell align="right">{row.trn_file_date}</TableCell>
                          <TableCell align="right">{row.date_procc}</TableCell>
                          <TableCell align="right">{row.trn_type_load}</TableCell>
                          <TableCell align="right">{row.executionTimeSeconds}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
        }else{
            return (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                     
                        <TableCell align="right">Fecha Proceso</TableCell>
                        <TableCell align="right">Chequeo</TableCell>
                        <TableCell align="right">LineasAntes</TableCell>
                        <TableCell align="right">LineasDespues</TableCell>
                        <TableCell align="right">Retorno SP TRN</TableCell>
                        <TableCell align="right">TablasActualizadas</TableCell>
                        <TableCell align="right">TRN Nombre Archivo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          
                          <TableCell align="right">{row.dateProc}</TableCell>
                          <TableCell align="right">{row.ok?'Proceso OK':'Error'}</TableCell>
                          <TableCell align="right">{row.rowsAfter}</TableCell>
                          <TableCell align="right">{row.rowsBefore}</TableCell>
                          <TableCell align="right">{row.storeReturnValue}</TableCell>
                          <TableCell align="right">{row.tablasActualizadas}</TableCell>
                          <TableCell align="right">{row.trnName}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
        }
   
}
