import React from 'react'
import { Alert } from '@mui/material';

export const InfoAlert = ({message}) => {


    return (
        <div>
             <Alert severity="info"> {message} </Alert>
        </div>
    )
}

