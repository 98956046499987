import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import Swal from 'sweetalert2';
import { clearDniSocio, setSocio } from '../../action/socios';
import SocioSearchModal from './ViewColumns/SocioSearchModal';

export default function SocioCodigoField() {

    const [socioCode, setsSocioCode] = useState(0)
    const [loadingCondition, setloadingCondition] = useState(false)
    const state = useSelector(state => state.sedes)
    const sociosDataReducer = useSelector(socios => socios)
    let toBtn =  sociosDataReducer.socios.loading
     

   const dispatch = useDispatch();
    
    let {sedeSelected, fullListSedes, change} = state

    useEffect(() => {

      if ( toBtn ){
        setloadingCondition(true)
      }else{
        setloadingCondition(false)
      }
      if (change){
        setsSocioCode(0)
      }
    }, [state, toBtn, change])

    sedeSelected=Number(sedeSelected);
    const numberTest = new RegExp('^[0-9]+$');

    const handleSocio=(socioCode, sedeSelected)=>{

     if ( sedeSelected) {
      const x = fullListSedes.find(x=>x.IDSede===sedeSelected)
      const {DNS,Port,System} = x ;
      if ( socioCode) {
        let dataToBackend = {
            "IDSede": Number(sedeSelected),
            "Codigo": Number(socioCode),
            "DNI":null,
            "DNS": DNS,
            "Port": Port,
            "System": System,
            "loaded": true,
            "loading": null
          }   
          let dataToLocal = {
            "IDSede": Number(sedeSelected),
            "Codigo": Number(socioCode)
          } 
          localStorage.setItem(dataToBackend.IDSede+dataToBackend.Codigo, JSON.stringify(dataToLocal))
          dispatch(setSocio(dataToBackend))
      }else{
        let dataToBackend = {
          "IDSede": Number(sedeSelected),
          "Codigo": null,
          "DNI":null,
          "DNS": DNS,
          "Port": Port,
          "System": System,
          "loaded": true,
          "loading": null
        }
        dispatch(setSocio(dataToBackend))
      }   

    }else{
      Swal.fire({
        icon: 'error',
        text:'Faltan Parametros'
      })
    }
    
  }

    const handleValidation=(e)=>{
        let a = e.target.value
        let anumber=Number(a)
        a=a.toString()
        if(numberTest.test(a) && anumber > 0 ){
            autoComplete(anumber) 
        }
        else{      
            setsSocioCode(0)         
        }
    }

    const autoComplete=(a,b=10)=>{
      let longSocioInput = Math.floor( Math.log(a) / Math.LN10 ) + 1 

        if (longSocioInput===b){
          setsSocioCode(Number(a))
          return;
        }

        if ( a && sedeSelected ){
        let howCeros=0;
        let longVariant=0;      
        let longSedeId = Math.floor( Math.log(sedeSelected) / Math.LN10 ) + 1    
         if (longSedeId===3){
            longVariant=longSedeId-1
         }else if (longSedeId===2){
            longVariant=longSedeId
         }else if (longSedeId===1){
            longVariant=longSedeId
         }

        if ( longSocioInput <  b-longVariant) {
                let test = a.toString()
                let xd = sedeSelected.toString()
                if (longSedeId<3){
                    howCeros = b-longSedeId-longSocioInput-1
                }else{
                    howCeros = b-longSedeId-longSocioInput
                }           
                for (let i = 0; i < howCeros; i++) {
                xd+='0'
        }
             setsSocioCode(xd.concat(test))
        }
        else {
            setsSocioCode(0)
        } 
    }    
    }
  return (
      <>
    <Box
      component="form"  
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },     
      }}
      noValidate
      onSubmit={
                    (e)=>{
                        e.preventDefault();                      
                        handleSocio(socioCode, sedeSelected);
                        dispatch(clearDniSocio())
                    }
        }
      autoComplete="off"
    >
      <TextField id="standard-basic" label="Codigo de Socio" variant="standard"
      type="number"
      inputProps={{ inputMode: 'numeric', pattern: '[09]*' }}     
      onChange={(e)=>{handleValidation(e)}}
      />
    
      <Button variant="outlined" disabled={loadingCondition} className='mt-3'  type='submit'>Consultar</Button>
      <SocioSearchModal disabled={loadingCondition}/>
    
       
    </Box>
    <h6 className='text-primary ms-1'>#{socioCode}</h6>    
    </>
  );
}
