//Sedes Activas
export const apiKeyValue='VHGFDn_2212ABCFDASDas*/21Dasd'
// DEV
//export const url = `http://mssql.sportclub.com.ar:4000/api/`
//export const urlApiPrecios = `https://apiprices.sportclub.com.ar:2000/api/`

//PROD
export const url = `https://apireports.sportclub.com.ar/api/`
export const urlApiPrecios = `https://apiprices.sportclub.com.ar:2000/api/`

//IN OFFICCE
//export const url = `http://192.168.10.32:4005/api/`
//export const urlApiPrecios = `http://192.168.10.32:5000/api/`


export const fetchActiveSedes = {
    api_key:apiKeyValue,
    url : `${url}sedes`,
    obs : 'Api Para listar Sedes Activas'
}

export const fetchSedesParams = {
    api_key:apiKeyValue,
    url : `${url}sedes/pmConn`,
    obs : 'Api Sedes con Parametros de Conexion'
}
 
export const fetchDa = {
    api_key:apiKeyValue,
    urlDaxe : `${url}reports/daxe`,
    obs : 'Api Reports to get Daxe Report'
}

export const fetchDaList = {
    api_key:apiKeyValue,
    urlDaxeList : `${url}fileList`,
    obs : 'Api Sedes con Parametros de Conexion'
}
 
export const fetchDaxeStatus = {
    api_key:apiKeyValue,
    urlDaxeList : `${url}reports/lastDaxeStatus`,
    obs : 'Api Sedes con Parametros de Conexion'
}
 

export const fetchStatusOfSystems = {
    api_key:apiKeyValue,
    url : `${url}globalData/statusOfSystem`,
    obs : 'Api Count Sedes group by System installed'
}

// Precios

export const fetchCambioDescContrato = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}contratos/contraCambioDescripcion`,
    obs : 'Api Lista de Contratos a la Venta'
}



export const fetchParamCambioEscala = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}contratos`,
    obs : 'Api Lista de Contratos a la Venta'
}


export const fetchPreciosVentaLista = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}precios/listPrecios`,
    obs : 'Api Lista de Contratos a la Venta'
}

export const fetchPreciosDashBoard = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}precios/dashBoard`,
    obs : 'Api DashBoard Lista de Precios'
}


export const fetchListAumentoFiles = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}precios/listAumentoFiles`,
    obs : 'Api Que devuelve los archivos de Aumento Base'
}

export const fetchContratosData = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}contratos/contratoData`,
    obs : 'Api para buscar contratos'
}

export const fetchEscalaData = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}escalas/escalasData`,
    obs : 'Api para buscar contratos'
}



// Backup

export const fetchRestoreData = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}backup/restoreData`,
    obs : 'Api Que devuelve el restore de las sedes'
}

export const fetchBackupData = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}backup/backupStatus`,
    obs : 'Api Que devuelve el estado del backup '
}

// Admin Panel
export const fetchActiveUsers = {
    api_key:apiKeyValue,
    url : `${url}session/listActiveUsers`,
    obs : 'Api Active Users Firebase'
}

// Status Of Data
export const getStatusProccMcurso = {
    api_key:apiKeyValue,
    url : `${url}processInfo/getProcessStaInfMCurso`,
    obs : 'Api Get Data Status'
}

//Proccess Of Data

export const runProccMCurso = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}runProccess/generateAumentoBase`,
    obs : 'Api Run Proc Aum Base'
}

export const fetchGenerateExcelChangePrice = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}precios/generateExcelChangePrice`,
    obs : 'Api Que genera los archivos de cambio de precios'
}

//SERVER ACTIONS 

export const fetchRunBackup = {
    api_key:apiKeyValue,
    url : `${url}serverActions/runAction`,
    obs : 'Api Que dispara un Backup en la sede'
}

