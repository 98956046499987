import React, { useEffect, useState } from 'react'
import MultiActionAreaCard from './DaxeCard'
import Stack from '@mui/material/Stack';
import { Alert, Box, Card } from '@mui/material';
import ImgWall1 from '../../../assets/imgwal1.jpg'
import { fetchDaxeStatus } from '../../../helpers/api_data';
import { formatDDMMYYYY } from '../../../helpers/date_converter';


export const DaxeScreen = () => {

  const [daxeReportStatus, setdaxeReportStatus] = useState(null)

  const styles = {
    paperContainer: {
        backgroundImage: `url(${ImgWall1})`
    }
    
};

useEffect(() => {
  const getDaxeStatus = async () => {
    const resp = await
        fetch(fetchDaxeStatus.urlDaxeList,{headers:{
          'apikey':fetchDaxeStatus.api_key
        }})
    const data = await resp.json()
    return data;
  }

  getDaxeStatus().then(r => {
    setdaxeReportStatus(r)
  })
}, [])

console.log(daxeReportStatus)

  return (
    <>
    <Box sx={{display:'flex', flexDirection:'column',padding:'30px', justifyContent:'center', alignItems:'center',
      
        backgroundImage: `url(${ImgWall1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        
    }}>
      <Card 

      sx={{ 
        width: '600px',
        height: 'auto',
        padding: '30px',
        borderBottom: '5px solid transparent',
       

      }}>
        <div className="animate__animated animate__fadeIn slow">
          <Stack spacing={2}
            sx={{
              width: '100%',
              padding: '1px',
              alignContent: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}  
                       >
            <Box sx={{
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems:'center'
            }}>
            <Alert severity="info"
              style={{ width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}
            >Daxe Compilado</Alert>

            {daxeReportStatus?.data.length > 0 && 
            <div className='d-flex justify-center items-center text-gray-900 mt-2 border-b-2'>
            Proceso: {formatDDMMYYYY( daxeReportStatus?.data[0]?.FPROCESO,'-')} | Sedes a Revisar: {daxeReportStatus?.data.map( r => (<p style={{marginLeft:'6px', color:'red' , fontWeight:'xl'}}>{ r.IDSEDE }</p>))}
            </div>
         
            }
               <p>Puede existir una fecha de proceso superior al ultimo daxe generado debido a que los daxe se procesan diariamente en el servidor</p>
            </Box>
          </Stack>
          <div className="mt-3" />
          <MultiActionAreaCard />
        </div>
      </Card>

     
      </Box>
    </>
  )
}
