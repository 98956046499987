import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { HandleDataToServer } from './HandleDataToServer';


  // Columnas Correctas
  let arrCheck = [
    "IDSede",
    "Categoria",
    "Definicion",
    "CtoBase",
    "Escala",
    "Promocion",
    "Cantidad Neta",
    "Valor(Titular)",
    "Nuevo Valor",
    "Fecha Hasta",
    "Fecha Desde",
  ];


export const HandleExcelData = ({data}) => {


  const [count, setCount] = useState(0);
  const [countErr, setCountErr] = useState(0);
  const [proc, setProc]  = useState(true);
  const [runProcAumento, setRunProcAumento] = useState(false);



  useEffect(() => {
   if (data ){
    setCount(data.length);    
         checkFile(data);
      setProc(false);
   }
  }, [data])
  
  function runAumento(){
    if  ( runProcAumento ){
      return(<>
        <HandleDataToServer dataToServer={data}     />
    </>)};
    return (<></>);
  }

  function viewValidColumns(){
    return (
      <>
      <Alert variant='danger'>Estructura de Columnas Invalida</Alert>
      <h6> Verificar que solo existan estas columnas</h6>
       <ul>
        {arrCheck.map(x=>(<li>
          {x}
        </li>
        ))}
       </ul>
       </>)
  }

    
  const checkFile =  (dataToCheck) => {

    let errors = 0;
   
        if (dataToCheck && dataToCheck.length > 0) {
          
      
            for (let i = 0; i < dataToCheck.length; i++) {
             
                const element = dataToCheck[i];
                let keys = Object.keys(element);
              
              for (let i = 0; i < keys.length; i++) {
                const r = keys[i];
                let find = arrCheck.find( x => x===r)
                if (!find) {
                   errors++
                }
              }               
            }    
          }    

          setCountErr(errors)
          if (errors===0){
            setRunProcAumento(true)
          }
          return true;
  };

    if ( data ){

      
        
        return (
            <div>
        
        {proc&&<>
          Procesando  {count} filas... espere 
        
        </>}
        { !proc&& ( {countErr}&& <> Carga Finalizada</> ) }
          
          <div>
          {!proc&& ( countErr>0 ? ( viewValidColumns()):(<>  <Alert variant='info'>Estructura de Columnas Correcta</Alert></>) )}
          </div>
          {runAumento()}

            </div>
          )
    }else{
        <>
        </>
    }
 
}
