import axios from 'axios';
import { useState, useEffect, useRef } from 'react'

export const useAxiosFetch =   ( url,api_key ) => {  

    const token  = sessionStorage.getItem('token-rp');


    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, errorDetail:null, loading: true, error: null, progress:null, totalSize:null });
    const client = axios.create({
        baseURL: url,
        timeout:0,
        headers: {'apikey': api_key,
            'x-token':token,
            'Content-Type': 'application/json'}   
      })

    useEffect( () => {
        return () => {
            isMounted.current = false;
        }
    }, [])
    useEffect( () => {
        setState({ data: null, loading: true, error: null });
        client.get(url,{onDownloadProgress: progressEvent => {
             const total = parseFloat(progressEvent.total)
            const loaded = parseFloat(progressEvent.loaded)
            let percentCompleted = Math.floor(loaded / total * 100)
            setState({...state, progress:percentCompleted, totalSize:total })
          } })
       .then( data => {       
                if ( isMounted.current ) {
                    setState({
                        loading: false,
                        error: null,
                        data: data.data
                    });
                }
            }).catch( (e) => {
                setState({
                    loading: false,
                    error: true,
                    data: null,
                    errorDetail: e.response
                });;
              });          
    },[url,api_key])

    return state;
}
