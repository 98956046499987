import React, { useState } from 'react'
import { QuerySendToBackend } from './QuerySendToBackend'
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';

export const QueryFinally = (data) => {

    //Data is Recived
    let dataToSend = data
    const [state, setstate] = useState(0)
    const [stateQueryBox, setStateQueryBox] = useState('')

    const handleTextAreaText = ({ target }) => {
        setStateQueryBox(target.value)
    }
    const handleSendQuery = () => {
        if (stateQueryBox.length>0){
            setstate(1)
        }else{
            Swal.fire({icon:'error',text:'Error, input is not detected'})
        }
        
    }
    if (state === 1) {
        let bringData = {
            data: dataToSend,
            query: stateQueryBox
        }
        return (
            <>
                <QuerySendToBackend dataResult={bringData} />
            </>
        )
    } else {
        return (
            <div style={{display:'flex', width:'500px', height:'auto'}}>

                <TextField id="filled-basic"  
                onChange={handleTextAreaText} 
                label="Aca va el Query" variant="standard"
                    rows="5"
                    fullWidth={true}
                />
                <Button variant="contained" color="primary"  style={{ width:'300px',border: '2px solid', padding: '2px' ,marginTop:'15px'}}
                    onClick={handleSendQuery}>Enviar al Backend</Button>
            </div>
        )
    }
}

