import { Button, Popover, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { ContinuousColorLegend } from 'react-vis';
import { fetchContratosData } from '../../../../helpers/api_data';
import { columnDefiner } from '../../../../helpers/autoColumnDefiner';
import { useFetch } from '../../../../hooks/useFetch'
import { TableMaterial } from '../../../MaterialTable/GlobalTableMaterial';
import { CambiarEscala } from './CambiarEscala';
import { contratoColumns } from './contratoColumns';
import {RenombrarContrato} from './RenombrarContrato';


export const BuscadorContrato = ({ apiParams }) => {

    const { value, tipe } = apiParams;

    const { data, loading, error } = useFetch(`${fetchContratosData.url}?${tipe}=${value}`, apiParams);

    const [showCambioEscala, setShowCambioEscala] = useState(false);
    const [showCambioDescripcion, setShowCambioDescripcion] = useState(false);
    const [disabled, setDisabled] = useState(true)
 

    useEffect(() => {
      if ( data ){
        if ( apiParams.tipe==='contrato'){
            setDisabled(false)
        }else{
            setDisabled(true)
        }
      }
       
    }, [data])
    

    const rowPanelTreg = ({ rowData }) => {

        let arr = [];

        let newOjb = Object.entries(rowData).map(([key, value]) => {

            if (typeof (value) !== 'object') {

                arr.push({ [key]: (typeof (value) === 'boolean' ? "Si" : value) })
            }

        });




        return (
            <div
                style={{
                    fontSize: 20,
                    textAlign: "left",
                    height: '100%',
                }}
            >
                <ul>
                    <li>

                        {
                            arr.map((data, index) => {

                                let objAttribute = Object.keys(data)
                                return (<li key={index}> {objAttribute} = {JSON.stringify(rowData[objAttribute])} </li>)
                            })
                        }
                    </li>
                </ul>
            </div>
        )
    }



    if ( data?.length===0) {
            return(<>
            <h6>Sin registros</h6>
            </>)
    }else if (data) {
        
        return (
            <div>
                <Button   disabled={disabled} onClick={ () => {setShowCambioEscala(!showCambioEscala)}}> Cambiar Escala </Button>
                <Button   disabled={disabled} onClick={ () => {setShowCambioDescripcion(!showCambioDescripcion)}}> RenombrarContrato </Button>
              
                {showCambioEscala&&<CambiarEscala contrato={data[0].Contrato}/>}
                {showCambioDescripcion&&<RenombrarContrato contrato={data[0].Contrato}/>}
                <TableMaterial
                    data={data}
                    columns={contratoColumns}
                    maxBodyHeight={'auto'}
                    rowPanel={rowPanelTreg}
                />


            </div>
        )
    } else if (loading) {
        return (
            <div>
                <h1>Cargando...</h1>
            </div>
        )
    } else {
        return (
            <div>
                <h1></h1>
            </div>
        )
    }
}
