import React, { useState } from 'react'
import SocioCodigoField from './SociosCodigoField'
import { Col, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { changeSede, isLoading, setSocioLoadedFalse } from '../../action/socios'
import { setChange, setFinishChange } from '../../action/sedes'
import { setSedeSelected } from '../../action/sedes'

export const SedeListPick = ({ data }) => {
    let fullListProclub = data;
    const dispatch = useDispatch()
    const [sedeSelectedStatus, setSede] = useState(0)
    
    const fnDepurer = (x) => {
        let xx = x.split('-');
        let xxx = xx[0];
        let xxxx = xxx.trim();
        if (sedeSelectedStatus === 0) {
            setSede(xxxx);
            dispatch(setSedeSelected(xxxx));
        } else {
            dispatch(isLoading(false));
            dispatch(setChange());
            dispatch(changeSede(xxxx));
            dispatch(setSedeSelected(xxxx));
            dispatch(setSocioLoadedFalse());
            setSede(xxxx);
            
            setTimeout(() => {
                dispatch(setFinishChange());
            }, 500);
            
        }
    }
    return (
        <>
            <Form.Select size="sm"
                onChange={
                    (e) => {
                        fnDepurer(e.target.value)
                    }
                }
            >
                <option>Pick a Sede</option>
                {
                    fullListProclub.map(x => (
                        <option key={x.IDSede}
                            id={x.IDSede}
                        >
                            {x.IDSede} - {x.Sede}
                        </option>)
                    )
                }
            </Form.Select>
            <Col>
                <SocioCodigoField />
            </Col>
        </>
    )
}
