import React from 'react';
import {  Grid, ListItem, Typography } from '@mui/material';

export const ShowSociosInLocal = ({ data }) => {


    if (data) {
        return (<>
            <div className='animate__animated animate__flipInX'>
                <Typography variant="overline" style={{ marginLeft: '8%', padding: 0 }} color='primary' display="block">
                    IDSede | #Ref
                </Typography>
                <Grid container spacing={0} columns={8}>
                    <Grid item xs={2}>
                        {data.map(x =>
                            <ListItem key={`${x.IDSede + x.Codigo + 3}`}>

                                <Typography variant='button'> {`${x.IDSede} `} </Typography>

                            </ListItem>
                        )}
                    </Grid>

                    <Grid item xs={4}>

                        {data.map(x =>
                            <ListItem key={`${x.IDSede + x.Codigo + 2}`}>

                                <Typography variant='button'> {` ${x.Codigo}`} </Typography>

                            </ListItem>
                        )}

                    </Grid>

                </Grid>
                <hr></hr>

            </div>
        </>);
    }

    else {
        return (
            <></>
        )
    }
};

