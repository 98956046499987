import { Box  } from '@material-ui/core'
import React, {useEffect} from 'react'
import { SedeSelector } from './SedeSelector'
import { useSelector } from 'react-redux'
import { setSociosSidebar } from '../../action/sideBar'
import { useDispatch } from 'react-redux';

export const SociosScreen = () => {
    //01
    const dispatch = useDispatch()
    const state = useSelector(state => state.sedes);
    const {fullListSedes} = state;


 

    useEffect(() => {
        dispatch(setSociosSidebar())
    }, [])
    


    return (
        <div>   
            
            <Box sx={{ marginTop:'15px'}}>
            <SedeSelector fullList={fullListSedes}/>
        </Box>       
        </div>
    )   
   
}
