import { createStore , combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import { authReducer } from '../reducers/authReducer';
import { componentReducer } from '../reducers/componentReducer';
import { sedesReducer } from '../reducers/sedesReducer';
import { sidebarReducer } from '../reducers/sidebarReducer';
import { sociosReducer } from '../reducers/sociosReducer';
import { uiReducer } from '../reducers/uiReducer';


//const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const reducers = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    sidebar: sidebarReducer,
    component: componentReducer,
    sedes: sedesReducer,
    socios: sociosReducer
});

export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware( thunk )
    )
    
);