import { Alert, AlertTitle, Box, Button } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { SedeSelector } from '../selector/SedeSelector';

import './Servidores.css';
import { ActionScreen } from './components/ActionScreen';


export const ServidoresScreen = () => {

  const [dataConn, setDataConn] = useState(null);

  
 const handleSelector = (sede, sedeFiltrada) => {
  setDataConn(sedeFiltrada);
 }

 const renderComponent = () => {
  if ( dataConn ){
    return ( <ActionScreen dataConn={dataConn} />)
  }else{
    return ( <></>)
  }

 }

  return (<>
    <Box>
        <SedeSelector handleSelector={handleSelector}/>
        <Alert severity="info">
  <AlertTitle>Acciones</AlertTitle>
</Alert>   
    </Box> 
  {renderComponent()}
    </>)


}
