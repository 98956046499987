import React from 'react'
import { fetchPreciosDashBoard } from '../../../helpers/api_data';
import { useFetch } from '../../../hooks/useFetch';
import BasicCard from './DashBoardCard';

export const PreciosDashBoard = () => {

    const { url, api_key } = fetchPreciosDashBoard;
    const { data, loading, error } = useFetch(url, api_key)



    if (data) {
        return ( <>
                <BasicCard data={data} />
            </>)
    } else if (loading) {
        return (<>
               <p>Caragando...</p>
            </>)
    } else if (error) {
        return (<></>)
    } else {
        return (<></>)
    }
}
