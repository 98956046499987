import { Alert, LinearProgress } from '@mui/material'
import { fetchStatusOfSystems } from '../../helpers/api_data'
import { useFetch } from '../../hooks/useFetch'
import './home.css';
import React, { PureComponent } from 'react';
import {PieChart,Pie,  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { SimpleBarChart } from '../charts/SimpleBarChart';


export const DashBoardMain = () => {

const {url, api_key}  = fetchStatusOfSystems
const {data, loading} = useFetch(url, api_key)

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

 const dataConstructor  = (params) => {
      let arrResult = []
    for (let i = 0; i < params.length; i++) {
      const element = params[i];
      let object = {
        name: 'Jc', value: element.JC
      }
      let objectTwo = {
        name: 'Proclub', value: element.Proclub
      }
      let objectThree = {
        name: 'Sistema',
         Prc: element.Proclub,
         Jc: element.JC,
         amt:500
      }
      arrResult.push(objectThree)
    }

    return arrResult;
 }

 const renderCustomizedLabel = ({
  x, y, name, value
  
}) => {

  return (
    <text x={x} y={y} fill="black" textAnchor="end" dominantBaseline="central">
      {name} {value}
    </text>
  );
};

if ( data ){
  return ( 
  <div  className='animate__animated animate__fadeIn slow pieGraph'>
  <SimpleBarChart data={dataConstructor(data)} />
  </div>
  )
  }else if (loading){
    return ( <p>...</p> )
  }else{
    return ( 
    <>
    <Alert> Err </Alert>
    </>)
  }

}
