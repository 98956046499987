import { Box, Button } from '@mui/material'
import moment from 'moment'
import React, { useEffect } from 'react'
import { getStatusProccMcurso, fetchRestoreData, fetchBackupData, fetchListAumentoFiles } from '../../../../helpers/api_data'
import { useFetch } from '../../../../hooks/useFetch'
import { TableMaterial } from '../../../MaterialTable/GlobalTableMaterial'
import { columnProcBase } from './CadenaColumns'

export const Cadena = () => {

  const [zipFile, setZipFile] = React.useState('');	


  

  const fnValidate = (fecha_1, fecha_2, fecha_3) => {
    if (   moment(fecha_1).isSame(moment(),'day')
    && moment(fecha_2).isSame(moment(),'day') 
    &&  moment(fecha_3).isSame(moment(),'day') ){
      return 'Estado OK'
  }else{
     return 'Informacion no actualizada'
 }
}
 
    const { data } = useFetch(getStatusProccMcurso.url, getStatusProccMcurso.api_key)

    const {url:url_2 , api_key:api_key_2} = fetchRestoreData;
    const { data:data_2, loading:loading_2, error:error_2 } = useFetch(url_2, api_key_2)

    const {url:url_3 , api_key:api_key_3} = fetchBackupData;
    const { data:data_3, loading:loading_3, error:error_3 } = useFetch(url_3, api_key_3)

    const {url:url_4 , api_key:api_key_4} = fetchListAumentoFiles;
    const { data:data_4, loading:loading_4, error:error_4 } = useFetch(url_4, api_key_4)


    useEffect(() => {
      if ( data_4){
        const file = data_4.find(x => x.name.includes('.zip'))
        if (file){
          setZipFile(file.url)
        }
      }
    }, [data_4])

    if ( data && data_2 && data_3 && data_4 ){
       
      
      
        let arr=[]
        for (let i = 0; i < data.length; i++) {
            let find = data_2.find(x => x.IDSede === data[i].IDSEDE);
            let find_2 = data_3.find(x => x.IDSede === data[i].IDSEDE);
            let find_4 = data_4.find(x => x.IDSede === data[i].IDSEDE);
            let date1=data.DATE;
            let date2=find?.UltimoRestore?find.UltimoRestore:'1900-01-01';
            let date3=find_2?.lastModified?find_2.lastModified:'1900-01-01';
           
            let url=find_4?.url?(find_4.url):('');
            let nameFile=find_4?.name?(find_4.name):('');
         
          let o ={
            IDSEDE:data[i].IDSEDE,
            FECHA:data[i].DATE,
            OUTPUT:data[i].STATUS,
            ULTIMORESTORE: date2,
            FECHABACKUP: date3,
            ESTADO: fnValidate(date1, date2, date3),
            URL:url,
            NAMEFILE:nameFile
          }
          arr.push(o)
          
        }

     
        return(
        <Box sx={{display:'flex', flexDirection:'column'}}>
          <Button variant='contained'  ><a href={zipFile} style={{color:'white'}} download>Descargar Zip Con todas las sedes</a></Button>
        <hr/>
        <TableMaterial data={arr} columns={columnProcBase} title='Aumento Base Proceso'/>
        </Box>)

    }else{
        return (<>
        <p>Cargando</p>
        </>)
    }

}
