import React from 'react'
import { Row, Col} from 'react-bootstrap';
export const LoadingScreen = () => {
    return (
        <div className='border d-flex align-items-center justify-content-center w-100 p-3' >
           <Row>
                  <Col md={{ span: 6, offset: 3 }}>     
                      <div className='container text-center'>   
                     <strong><span> Cargando ...</span></strong>
               
               </div>
               </Col>
            <Col md={{ span: 6, offset: 3 }}>        
              </Col>
         </Row>
      </div>           
          
               
    )
}
