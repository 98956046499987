/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState,  } from "react";

import { Box, Typography, List, ListItem, ListItemText,  Card, CardContent, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import PacmanBigoton from '../../../../assets/skaterguy.gif'
import { fetchRunBackup } from "../../../../helpers/api_data";

import { HeadersServerSedes } from "../../../../helpers/HeadersServerSedes";



const InfoCard = styled(Card)({
  maxWidth: '300px',
  minWidth: '250px',
  margin: '1rem',
  boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
});

const InfoCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const InfoBox = ({ title, data }) => {
  return (
    <InfoCard>
      <InfoCardContent>
        <Typography variant="h6" color={'primary'} gutterBottom>
          {title}
        </Typography>
        <List>
          {Object.keys(data).map((key) => (
            <ListItem key={key}>
              <ListItemText
                primaryTypographyProps={{ fontWeight: 'bold' }}
                primary={key.toUpperCase()}
                secondary={data[key]}
              />
            </ListItem>
          ))}
        </List>
      </InfoCardContent>
    </InfoCard>
  );
};


const BeautifulInterface = ( dataRecived ) => {
  
  if ( dataRecived ){
    return (
      <div style={{ display: 'flex', justifyContent: 'center',  }}>
        <InfoBox title="CPU" data={dataRecived?.cpu[0]} />
        <InfoBox title="RAM" data={dataRecived?.ram[0]} />
        <InfoBox title="DISK" data={dataRecived?.disk[0]} />
        </div>
    );
  }else{
    <>
    ...
    </>
  }
};

export  const  InfoComponent =( { action, sedeConn })  => {

  const [data, setData] = useState({
    data:null,
    error:null,
    loading:true
  });

      useEffect(() => {
    setData({  
       data:null,
      error:null,
      loading:true})
    async function fetchData() {
      try {
        const response = await fetch(`${fetchRunBackup.url}?sede=${sedeConn.IDSede}&&action=${action}`,{
          headers:HeadersServerSedes
        });
        const json = await response.json();
       

            if ( json.data?.cpu){
              setData({data:json.data, loading:false,error:null});
            }else{
          setData({data:null, loading:false, error:json.data });
            }
        
      } catch (error) {
       
        setData({data:null, loading:false, error:error});
      }

    }
    fetchData();

    return () => {
      setData({  
        data:null,
       error:null,
       loading:true})
    }

  }, [sedeConn]);



if ( data.loading ){
  return(<>
  Cargando... 
  </>)
}else if ( data.error){
  return(<>
    {data.error}
    </>)
}else if(data.data){
  return(
    <>
    <Divider/>
    {BeautifulInterface(data.data)}
    </>
  )
}else{
  return(
    <>
    </>
  )
}



    
}





