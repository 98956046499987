import { types } from "../types/types"

export const setComponentPreciosVenta = () => ({
    type: types.setListComponent,
    payload: 'Precios' 
});

export const setComponentPreciosDash = () => ({
    type: types.setPreciosDashboard,
    payload: 'Precios'
});

export const setComponentAumento = () => ({
    type: types.setAumentoBase,
    payload: 'Precios'
});

export const setComponentBuscador = () => ({
    type: types.setBuscador,
    payload: 'Precios'
});

export const setComponentTRN = () => ({
    type: types.setTRN,
    payload: 'Precios'
});

export const setListDaxeComponent = () => ({

    type: types.setListDaxe
})

export const setLoadOn = () => ({
    type: types.setLoad  
});

export const setLoadOff = () => ({
    type: types.unsetLoad    
});

export const clearComponent = () => ({
    type: types.clearComponent 
});

export const addSede = () => ({
    type: types.addsede 
});

export const setSedesTable = () => ({
    type: types.sedes 
});