import { useState, useEffect, useRef } from 'react';


export const useFetchPost =  ( url, api_key, body ) => {  

    const token  = sessionStorage.getItem('token-rp');

    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, loading: true, error: null, errorDetail:null });  

    useEffect( () => {
        return () => {
            isMounted.current = false;
        }
    }, [])
    useEffect( () => {
        setState({ data: null, loading: true, error: null, errorDetail:null });     
         fetch( url,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors',
            timeout: 6000, // no-cors, *cors, same-origin
           // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
           // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              'apikey': api_key,
              'x-token':token
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            //redirect: 'follow', // manual, *follow, error
             //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(body) // body data type must match "Content-Type" header)
            })
            .then( resp => resp.json() )
            .then( data => {          
          
                if ( isMounted.current ) {    
                    setState({
                        data,
                        loading: false,
                        error: null,
                        errorDetail: null
                        
                    });
                }
            }).catch( (e) => {
                setState({
                    data:null,
                    loading: false,
                    error: true,
                    errorDetail: e
                });
              });          
    },[url, api_key, body])

    return state;
}