import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export const AddSedeScreen = () => {
  const [formData, setFormData] = useState({
    dbNameReportes: "Proclub5_",
    onRestore: 0,
    Actual: 1,
    EsProclub: 1,
    gedInformes: 1,
    onTest: 0,
    UsaTG:0,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.UsaTG !== 0 || formData.UsaTG !== 1) {
      alert("UsaTG debe ser 0 o 1");
    }
    if (formData.gedInformes !== 0 || formData.gedInformes !== 1) {
      alert("gedInformes debe ser 0 o 1");
    }
    console.log(formData);
  };

  useEffect(() => {
    if (formData.id) {
      setFormData({ ...formData, dbNameReportes: "Proclub5_" + formData.id });
    }
  }, [formData.id]);

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            padding: "45px",
            margin: "15px",
            overflow: "hidden",
            boxShadow: 5,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "10px" }}
            gutterBottom
          >
            <Icon sx={{ color: "green" }}>add_circle</Icon>
            ALTA DE SEDE
          </Typography>

          <Divider flexItem>
            <Chip label="Tabla Sedes" />
          </Divider>

          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginTop: "15px" }}
          >
            <Grid
              container
              sx={{
                margin: "0px",
                gap: "5px",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <FormControl
                onChange={(e) =>
                  setFormData({ ...formData, id: e.target.value })
                }
              >
                <InputLabel htmlFor="id">ID</InputLabel>
                <OutlinedInput type="number" id="id" label="id" />
              </FormControl>

              <FormControl
                required
                onChange={(e) =>
                  setFormData({ ...formData, descripcion: e.target.value })
                }
              >
                <InputLabel htmlFor="descripcion">Descripcion</InputLabel>
                <OutlinedInput id="descripcion" label="descripcion" />
              </FormControl>

              <FormControl
                required
                onChange={(e) =>
                  setFormData({ ...formData, UsaTG: e.target.value })
                }
              >
                <InputLabel htmlFor="UsaTG">UsaTG</InputLabel>
                <OutlinedInput   value={formData.UsaTG} type="number" id="UsaTG" label="UsaTG" />
              </FormControl>

              <FormControl required disabled>
                <InputLabel htmlFor="dbNameReportes">dbNameReportes</InputLabel>
                <OutlinedInput
                  value={formData.dbNameReportes}
                  id="dbNameReportes"
                  label="dbNameReportes"
                />
              </FormControl>

              <FormControl
                onChange={(e) =>
                  setFormData({ ...formData, gedInformes: e.target.value })
                }
              >
                <InputLabel htmlFor="gedInformes">gedInformes</InputLabel>
                <OutlinedInput
                  type="number"
                  id="gedInformes"
                  label="gedInformes"
                  value={formData.gedInformes}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Divider flexItem sx={{ marginTop: "15px" }}>
            <Chip label="Tabla SedesConn" />
          </Divider>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginTop: "15px" }}
          >
            <Grid
              container
              item
              sx={{
                margin: "0px",
                gap: "10px",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <FormControl
                onChange={(e) =>
                  setFormData({ ...formData, DNS: e.target.value })
                }
              >
                <InputLabel htmlFor="DNS">DNS</InputLabel>
                <OutlinedInput id="DNS" label="Dns" />
              </FormControl>
              <FormControl
                onChange={(e) =>
                  setFormData({ ...formData, IP: e.target.value })
                }
              >
                <InputLabel htmlFor="Ip">Ip</InputLabel>
                <OutlinedInput id="Ip" label="Ip" />
              </FormControl>

              <FormControl
                onChange={(e) =>
                  setFormData({ ...formData, Port: e.target.value })
                }
              >
                <InputLabel htmlFor="Port">Port</InputLabel>
                <OutlinedInput type="number" id="Port" label="Port" />
              </FormControl>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="inherit">
            Agregar
          </Button>
        </Box>
      </form>
    </div>
  );
};
