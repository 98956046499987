import { formatDDMMYYYY, formatDDMMYYYYMMHH } from "../../../../helpers/date_converter";
import  moment from 'moment';
import { DownloadFiles } from "../../../../helpers/downloadFiles";
import { apiKeyValue } from "../../../../helpers/api_data";

export  const columnProcBase = [
    {  
        
        id: 1,
        DT_RowId: "1",
        title:'IDSede',
        field: 'IDSEDE',
        filtering: true, 
        width:'11%',
        },
        {  
        
            id: 2,
            DT_RowId: "2",
            title:'Salida',
            field: 'OUTPUT',
            filtering: true,
            width:'11%',
        },
    {  
        
id: 3,
DT_RowId: "3",
title:'Fecha Proceso',
field: 'FECHA',
filtering: true,
cellStyle:(rowData) => {
        if ( !moment(rowData.FECHA).isSame(moment(),'day') ){
            return {backgroundColor: "#F2C9C1"}
        }else{
            return {backgroundColor: "#FFF"}
        }
},
render: rowData => {
    return  formatDDMMYYYYMMHH(rowData.FECHA,'-')
  },
  headerStyle: {
  }
},

{  
        
    id: 4,
    DT_RowId: "4",
    title:'Fecha Restore',
    field: 'ULTIMORESTORE',
    filtering: true,
    render: rowData => {
        return  formatDDMMYYYYMMHH(rowData.ULTIMORESTORE,'-')
      },
},
{  
        
    id: 5,
    DT_RowId: "5",
    title:'Fecha Backup',
    field: 'FECHABACKUP',
    filtering: true,
    render: rowData => {
        return  formatDDMMYYYYMMHH(rowData.FECHABACKUP,'-')
      },
},
{
    id: 6,
    DT_RowId: "6",
    title: "Estado",
    field: "ESTADO",
    filtering: true,
},
{
    
    id:7,
    DT_RowId: "7",
    title: "Descargar",
    field: "URL",
    align:'center',
    filtering: false,
    headerStyle: {
        textAlign: "center",
    },
    render: rowData => {
      
        return (<><DownloadFiles url={rowData.URL} name={rowData.NAMEFILE} api_key={apiKeyValue}  /> </>)
    }
  }
]
