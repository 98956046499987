import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom'


export const NoPermission = () => {

    const history = useNavigate();
    const [seconds, setSeeconds] = useState(3);

    useEffect(() => {

        setTimeout(() => {
            if (seconds > 0 ){
                setSeeconds(seconds - 1)
            }else if (seconds === 0){
                history("/login") 
            }
        }, 1000);
       

    }, [seconds, history]);
    
    
  return (<div>
            <h1> Permiso Denegado! </h1>
            <h1> Redireccion en:  {seconds===0?('¡Saludos desde GM!'):(seconds)} </h1>
            
          </div>);
};
