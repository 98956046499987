import { urlApiPrecios, url, apiKeyValue } from "./api_data"

//Sedes Activas

export const getSocioData = {
    api_key:apiKeyValue,
    url : `${url}socios/datoSocio`,
    obs : 'Api to get Socio Data'
}

export const getSocioByDni = {
    api_key:apiKeyValue,
    url : `${url}socios/findByDni`,
    obs : 'Api to get Socio ID finded by DNI'
}

export const postFullQuery = {
    api_key:apiKeyValue,
    url : `${url}massiveQuery/test`,
    obs : 'Api to Run a Massive Query'
}

export const postSedesToRestore = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}backup/startRestore`,
    obs : 'Api send array data to be REstored'
}

export const postAddSedes = {
    api_key:apiKeyValue,
    url : `${url}sedes/add`,
    obs : 'Api send array data to be REstored'
}



// precios


export const postUploadAumento = {
    api_key:apiKeyValue,
    url : `${urlApiPrecios}precios/uploadDataAuBase`,
    obs : 'Api send array data to be REstored'
}



