export  const columns = [
    {  
id: 1,
DT_RowId: "1",
title:'#',
field: 'IDSede',
width:120
},
{  
    id: 2,
    DT_RowId: "2",
title:'Sede',

field: 'Sede'
},
{  
    id: 3,
    DT_RowId: "3",
title:'ID_CtoBase',

field: 'Ctobase'
},

{  
    id: 4,
    DT_RowId: "4",
title:'ID_Escala',

field: 'Escala'
},
{  
    id: 5,
    DT_RowId: "5",
title:'Promocion',

field: 'Descripcion'
},
{  
    id: 6,
    DT_RowId: "6",
title:'Mod',

field: 'Modalidad'
},
{  
    id: 8,
    DT_RowId: "8",
title:'Descuento?',

field: 'PermitirDescuentos'
},
{  
    id: 9,
    DT_RowId: "9",
title:'PromoAmigo?',

field: 'PromoAmigo'
},
{  
    id: 10,
    DT_RowId: "10",
title:'ValorTitular',

field: 'ValorTitular'
},

{  
    id: 11,
    DT_RowId: "11",
title:'Zona',
field: 'Zona',
},{  
    id: 12,
    DT_RowId: "12",
title:'ValorArancel',
field: 'ValorArancel',
}
]

export  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    };
    