
import React from "react";
import MaterialTable from "@material-table/core";
import { downloadExcel } from "../../hooks/useExcelExport";
import { ErrorAlert } from "../GlobalMessages/Error";

export const CtaCteTableMaterial = React.memo(({data,columns,title}) => {

    let dataOrderDesc = data
    //Original Data Orderder DESC
    dataOrderDesc.sort(function(a, b) {
    return  b.Row - a.Row;
  });

  if ( data && columns) {

    return (
      
      <MaterialTable  
        title={title}
        columns={columns}
        data={dataOrderDesc}
           options={{   
          filtering: true,
          columnsButton: true,
          cursor:'none',
          headerStyle: { 
            position: 'sticky',
            textAlign:'center',
            justifyContent:'center',
            alignContent:'center',
            alignItems:'center',
             top: 1,  
              backgroundColor:
               "#01579b",
            color: "#FFF", },
          maxBodyHeight: '450px' ,
          columnsHiddenInColumnsButton: true,
          grouping: true,
          padding:'dense',
          pageSize:70,
          pageSizeOptions: [5, 20, 50],
          paging: true,
          fixedHeader:true,     
          rowStyle: (rowData) => {
            if ( rowData.Anulado) {
              return {backgroundColor: "#DC4120"}
            }else if ( rowData.Cuota===1 && !rowData.Anulado && ( rowData.Detalle.includes('---ORIGINAL---') || rowData.Cuota===1  )   ){
              return {backgroundColor: "#B4E7D7"}
            }else if (rowData.Detalle.includes('BAJA ANTICIPADA')){
              return {backgroundColor: "#F8AA31"}
            }
          },
          exportMenu: [   
          {
            label: 'Bajar a Excel',
            exportFunc: () => downloadExcel(data)
          }            
        ]
        }}  
      />

   );
  }else{
    return(
      <ErrorAlert message='Error al cargar Tabla'/>
    )
  }

 

 
});
