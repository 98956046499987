import { types } from "../types/types"


const initialState = {
    sedeSelected:null,
    fullListSedes:null,
    change: false
}

export const sedesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.setSedeSelected:
            return {
               ...state,
               sedeSelected:action.payload
            }
        case types.populateSedeList:
        return {
            ...state,
            fullListSedes:action.payload
        }

        case types.setChange:
            return {
                ...state,
                change:true
            }

            case types.setFinishChange:
            return {
                ...state,
                change:false
            }

            case types.sedeSelectedClean:
            return {
                ...state, sedeSelected:null   
            }  

            

        default:
            return state;
    }
}