import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import Input from "@mui/material/Input";
import FormLabel from "@mui/material/FormLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { apiKeyValue, url } from "../../../../helpers/api_data";
import Swal from "sweetalert2";
const UpdateSocio = ({ dataFromApi, handleSubmitSocio }) => {
  const initialState = {
    apto_medico: "",
    vigencia: "",
  };

  const [formValues, setFormValues] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
   
    event.preventDefault();

    if (dataFromApi) {
      const token = sessionStorage.getItem("token-rp");

      try {
        const response = await fetch(url + "apiProclub" + "/update/socio", {
          body: JSON.stringify({
            apto_medico: formValues.apto_medico,
            vigencia: formValues.vigencia,
            codigo: dataFromApi.NroSocio,
          }),
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            apikey: apiKeyValue,
            "x-token": token,
          },
        });

        if (!response.ok) {
          const respData = await response.json()
          Swal.fire({
            title: "<strong>Fallo Proceso</strong>",
            icon: "error",
            text: `Error detectado: ${JSON.stringify(respData)}`,
          //  onClose:   handleSubmitSocio(event)
          })
        }
        const respJson = await response.json();

      Swal.fire({
          title: "<strong>Proceso Ok</strong>",
          icon: "info",
          text: `Lineas Actualizadas: ${respJson?.affectedRows}`,
          onClose:   handleSubmitSocio(event)
        })

       


      } catch (err) {
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            margin: "5px",
            display: "flex",
            marginLeft: "5px",
          }}
        >
          <div
            style={{
              margin: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "2px solid black",
              paddingBottom: "5px",
            }}
          >
            <FormLabel
              sx={{
                width: "100%",
                background: "#121212",
                color: "yellow",
                padding: "10px",
              }}
            >
              Nueva Fecha Apto Medico
            </FormLabel>
            <Input
              type="date"
              label="Fecha Apto Medico"
              value={formValues.apto_medico}
              onChange={(e) =>
                setFormValues({ ...formValues, apto_medico: e.target.value })
              }
              inputProps={""}
            />
          </div>

          <div
            style={{
              margin: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "2px solid black",
              paddingBottom: "5px",
            }}
          >
            <FormLabel
              sx={{
                width: "100%",
                background: "#121212",
                color: "yellow",
                padding: "10px",
              }}
            >
              Nueva Fecha Vigencia
            </FormLabel>
            <Input
              type="date"
              label="Nueva Fecha vigencia"
              value={formValues.vigencia}
              onChange={(e) =>
                setFormValues({ ...formValues, vigencia: e.target.value })
              }
              inputProps={""}
            />
          </div>
          <div>
            <div className="flex flex-col mt-3">
              <p>Limpiar</p>
              <FontAwesomeIcon
                onClick={() => setFormValues(initialState)}
                size="xl"
                icon={faTrashCan}
              />
            </div>
          </div>
          <div></div>
        </Box>
        <Box
          sx={{
            margin: "5px",
            display: "flex",
            marginLeft: "8px",
          }}
        >
          {(formValues.apto_medico.length > 0 ||
            formValues.vigencia.length > 0) && (
            <Button
              type="submit"
              style={{
                background: "#121212",
                color: "white",
              }}
              variant="contained"
            >
              {" "}
              ( ) Procesar
            </Button>
          )}
        </Box>
        {loading && <p>Cargando...</p>}
      </form>
    </>
  );
};

export default UpdateSocio;
