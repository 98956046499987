import * as XLSX from "xlsx";
import { lettersArrayUpperCase } from "../helpers/letters";

export const downloadExcel=(data, title) => {
  let columnsLenght = Number(Object.keys(data[0]).length-1);

//docs https://github.com/SheetJS/sheetjs

    const workSheet=XLSX.utils.json_to_sheet(data);
    workSheet['!autofilter'] = { ref:`A1:${lettersArrayUpperCase[columnsLenght]}1` };
    const workBook=XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(workBook,workSheet,'Test.xlsx')
    
    //let Buffer=XLSX.write(workBook,{bookType:"xlsx", type:"buffer"})
    //Binary String 
    XLSX.write(workBook,{bookType: "xlsx", type:"binary"})
    //Download
    XLSX.writeFile(workBook,`${title?title:'Archivo'}.xlsx`)
  }
  
