import React from 'react'
import { SocioSearchApiRun } from './ViewColumns/SocioSearchApiRun';

export const SocioSearchApi = ({information, sedeData}) => {

  
    const body ={
        ...sedeData,
        DNI:information
    }

    return(
        <>
        <SocioSearchApiRun body={body}> </SocioSearchApiRun>
        </>
    )
    
}
