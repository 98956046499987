
import React from 'react'
import { Table } from 'react-bootstrap'
import { formatDDMMYYYY, YYYYMM } from '../../../helpers/date_converter'


export const SocioModalDebtsTable = ({data}) => {
 

    return (
        <>
            <Table responsive  bordered hover size="sm" width={'100%'}
           >
  <thead  className='bg-light text-dark text-center '>
    <tr>
      <th>ContratoID</th>
      <th>ContratoAU</th>
      <th>DA</th>
      <th>Vencimiento</th>
      <th>Rechazo</th>
      <th>Periodo</th> 
      <th>ContratoDesc</th>
      <th>Tarjeta</th>
      <th>Importe</th>
    </tr>
  </thead>
  <tbody>
      {
      data.map(x=>(
        <tr key={x.ContratoId}>
            <td>{x.ContratoId}</td>
            <td>{x.ContratoAU}</td>
            <td>{x.DA?('Si'):('No')}</td>
            <td>{ 
                formatDDMMYYYY(x.Vencimiento,'-')
            }</td>

            <td 
            width={'auto'}
            >{x.RechazoDesc}</td>
            <td>{     
            YYYYMM(x.Periodo,'/')
            }</td>
            <td>{x.PlanDescCA.toUpperCase()}</td>
            <td>{x.TarjetaDesc}</td>
            <td>{x.Importe}</td>
            


        </tr> 
      ))
      }
  </tbody>
</Table>


        </>
    )
}
