import { Button, LinearProgress, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { TableMaterial } from '../MaterialTable/GlobalTableMaterial';

export const BackupTable = ({...props}) => {

    const { data, columnBackup, handleRestore, rowPanelEmpty, handleRowCondition } = props;
    const [dataFilter, setDataFilter] = useState('');
    const [originalData, setOriginalData] = useState('');
    const [dataShow, setDataShow] = useState('');
    const [tableLoad, setTableLoad] = useState(false);
    
    useEffect(() => {
     if ( data ){
         setOriginalData(data)
     }
    }, [])
          

    useEffect(() => {  
        if ( data ){       
        let newData = data.filter(x => x.Estado!=='OK')
        setDataFilter(newData)
        setDataShow(newData)
        }
       
    }, [data])

    const handleFilter = ( checked) => {
        if (checked ){
            setTableLoad(true);
            setDataShow(originalData);
            setTimeout(() => {
                setTableLoad(false);
            }, 1500);
        }else{
            setTableLoad(true);
            setDataShow(dataFilter);
            setTimeout(() => {
                setTableLoad(false);
            }, 1500);
        }
    }
    
 if ( dataFilter ){
    return (
        <>
             <Button onClick={ () => {handleRestore(data)}} variant="contained">Restaurar</Button>
            <Switch   color="secondary" label={'Mostar Todo'} onClick={(SwitchProps) => {handleFilter( SwitchProps.target.checked)} }  /> Mostar Todo
            <TableMaterial 
            data={dataShow}
             columns={columnBackup}
              title={'Backups'} 
              rowPanel={rowPanelEmpty}
               rowCondition={handleRowCondition}
                isLoading={tableLoad}
               
               />
        
        </>
      );
 }else{
     return ( <LinearProgress></LinearProgress> );
 }
 
}
