import { apiKeyValue } from "./api_data";

 const token  = sessionStorage.getItem('token-rp') || '';


export const HeadersServerSedes={

        'Content-Type': 'application/json',
        'apikey': apiKeyValue,
        'x-token':token
      }

