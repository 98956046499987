import React from 'react'
import { SedeSelector } from '../../selector/SedeSelector'

export const TrnScreen = () => {
  return (
    <>
    <h6>TRN SCreen</h6>
    <SedeSelector/>
    </>
  )
}
