import React, { useEffect } from 'react'
import { Alert, TextField, Button, MenuItem, InputLabel, Select, FormControl, Divider, Fab } from '@mui/material'
import Box from '@mui/material/Box';
import { useState } from 'react';
import { fetchContratosData } from '../../../helpers/api_data';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import './buscador.css';
import { BuscadorContrato } from './contrato/BuscadorContrato';
import Grid from '@mui/material/Grid';
import { BuscadorEscala } from './escala/BuscadorEscala';

export const BuscadorScreen = () => {

  const [formState, setFormState] = useState(
    {
      tipe: '',
      value: '',
      goSearch: false,
    });

    const [formStateEscalas, setFormStateEscalas] = useState(
      {
        tipe: '',
        value: '',
        goSearch: false,
      });

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormState({ ...formState, goSearch: true })
  }

  const handleSubmitEscalas = (e) => {
    e.preventDefault();
    setFormStateEscalas({ ...formStateEscalas, goSearch: true })
  }

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Alert >Contratos</Alert>
          <Divider />
          <Box sx={{
            marginTop: '15px'
          }}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <FormControl sx={{
                width: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'align-left'
              }}
              >
                <InputLabel id="demo-simple-select-label">Opcion Filtrado</InputLabel>
                <Select
                  sx={{ width: '200px', marginRight: '10px' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formState.tipe}
                  label="Opcion Filtrado"
                  onChange={(e) => setFormState({ ...formState, tipe: e.target.value, value: '', goSearch: false })}
                >
                  <MenuItem value={'contrato'}>ID</MenuItem>
                  <MenuItem value={'desc'}>Descripcion Aproximada</MenuItem>
                  <MenuItem value={'valor'}>Valor</MenuItem>
                  <MenuItem value={'descEqual'}>Descripcion Exacta</MenuItem>
                </Select>
                <TextField id="standard-basic" label="Colocar Valor" variant="standard" value={formState.value}
                  onChange={(e) => setFormState({ ...formState, value: e.target.value, goSearch: false })} />
              </FormControl>
              <button type='submit' >
                <Fab size="small" color="secondary" aria-label="add">
                  <SearchOutlinedIcon />
                </Fab>
              </button>
            </form>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Alert >Escalas</Alert>
          <Divider />
          <Box sx={{
            marginTop: '15px'
          }}>
            <form onSubmit={(e) => handleSubmitEscalas(e)}>
              <FormControl sx={{
                width: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'align-left'
              }}
              >
                <InputLabel id="demo-simple-select-label">Opcion Filtrado</InputLabel>
                <Select
                  sx={{ width: '200px', marginRight: '10px' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formStateEscalas.tipe}
                  label="Opcion Filtrado"
                  onChange={(e) => setFormStateEscalas({ ...formStateEscalas, tipe: e.target.value, value: '', goSearch: false })}
                >
                  <MenuItem value={'escala'}>Escala ID</MenuItem>
                  <MenuItem value={'desc'}>Descripcion Aproximada</MenuItem>
                  
                </Select>
                <TextField id="standard-basic" label="Colocar Valor" variant="standard" value={formStateEscalas.value}
                  onChange={(e) => setFormStateEscalas({ ...formStateEscalas, value: e.target.value, goSearch: false })} />
              </FormControl>
              <button type='submit' >
                <Fab size="small" color="secondary" aria-label="add">
                  <SearchOutlinedIcon />
                </Fab>
              </button>
            </form>
          </Box>
        </Grid>
      </Grid>
      <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
      {formState.goSearch && <BuscadorContrato apiParams={formState} />}
      </Grid> 
      <Grid item xs={6}>
      {formStateEscalas.goSearch && <BuscadorEscala apiParams={formStateEscalas} />}
        </Grid>           
        </Grid>       
      </div>
    </>
  )
}
