import React, {useEffect} from 'react'
import {fetchActiveSedes} from '../../helpers/api_data.js'
import { useFetch } from '../../hooks/useFetch';
import { useDispatch } from 'react-redux';
import { setSedesSidebar } from '../../action/sideBar.js';
import { TableMaterial } from './SedesTableMaterial';
import { ErrorAlert } from '../GlobalMessages/Error.js';
import { useSelector } from 'react-redux';
import { AddSedeScreen } from './AddSede/AddSedeScreen.js';


export const SedesScreen = React.memo(() => {
    
    const {url, api_key} = fetchActiveSedes;
    const {data,  loading, error } = useFetch(url,api_key)
    const { mainComponent, display } = useSelector((state) => state.component);
    const dispatch = useDispatch()



    useEffect(() => {
        dispatch(setSedesSidebar())
    }, [])
    
 if ( display === 'AddSede' && mainComponent === 'Sedes') {
        return ( 
            <AddSedeScreen/> )
    }

 if (data){ 
            return ( 
            <TableMaterial resp={data} ></TableMaterial> )      
            
    }else if (error){
        return(
        <ErrorAlert message='Error al listar Sedes' />
        )
        
    }else{
        return (
                <div className="mt-5">
                {loading && <p>Cargando...</p>}
                <strong><span>Cargando Listado de Sedes... espere</span></strong>
                </div>
           )
    }


   
})
