import React, { useEffect, useState } from 'react'
import { fetchDa } from '../../../helpers/api_data'
import { ErrorAlert } from '../../GlobalMessages/Error';
import { useAxiosFetch } from '../../../hooks/useAxiosFetch';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { setLoadOff, setLoadOn } from '../../../action/sideBarComponents';
import PacmanBigoton from '../../../assets/Papeador.gif'
import { DaxeInterval } from './DaxeInterval';
import LightbulbIcon from '@mui/icons-material/Lightbulb';


export const DaxeStartLoading = () => {

    const {urlDaxe, api_key} =  fetchDa
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(false)
    const  {data,  loading, error, errorDetail }= useAxiosFetch(urlDaxe,api_key);
    
    const parseName = (name) => {
        return name.substring(49,72)
    }

    useEffect(() => {    
        if ( loading ){
            dispatch(setLoadOn());
        }else{
            dispatch(setLoadOff());
        }
    }, [loading, dispatch]);


    if (data){       
        return ( 
            <>  
            <Box disabled={disabled} sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'30px'}}>
          <ul>
              <li>
              <Typography  variant='subtitle' color={'primary'}>Datos Leidos Con Exito en  <strong> <DaxeInterval/> </strong>  segundos sera redirigido al listado de archivos </Typography>
            </li>
            <li>
            <Typography variant='subtitle'> <LightbulbIcon style={{color:'red'}}/> 3 Minutos es el tiempo de carga estimado del Daxe </Typography>
            </li>
          </ul>
           </Box>
           </>
        )        
    }else if(loading){
    return (
        <>
        <Box sx={{display:'flex', flexDirection:'column', alignContent:'center', 
        justifyContent:'center', marginTop:'30px' , marginLeft:'15%'}}>
        <Typography variant='h6' color={'primary'}>  Iniciando Carga </Typography>
        <Typography variant="subtitle1" color={'secondary'}> Cargando Registros desde la Base de Datos </Typography>
            <img width='200px' alt='pacmanCat' src={PacmanBigoton}></img>  
          </Box>
          </> )
}else if (error){

    return(
        <>
        <ErrorAlert message='Err loading Daxe' />
        <ErrorAlert message={errorDetail?.data?.status} />
        </> 
    )
}
}



