import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Fab,
  Grid,

  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Cadena } from "./Cadena/Cadena";
import NavigationIcon from "@mui/icons-material/Navigation";
import SkeletonSimple from "../../GlobalMessages/Skeleton";
import {
  fetchGenerateExcelChangePrice,
  runProccMCurso,
} from "../../../helpers/api_data";
import { BaseScreen } from "./Base/BaseScreen";
import { ErrorAlert } from "../../GlobalMessages/Error";

export const AumentoBaseScreen = () => {
  const [generateAumentoBase, setGenerateAumentoBase] = useState("");
  const [msgError, setMsgError] = useState("");
  const [processAumentoBase, setProcessAumentoBase] = useState(false);
  const [processAumentoBaseMsg, setProcessAumentoBaseMsg] = useState(null);

  const handleAumentType = (e) => {
    const nameButton = e.target.name;
    setGenerateAumentoBase(nameButton);
  };

  const handleProcessAumento = () => {
    setGenerateAumentoBase("");
    setProcessAumentoBase(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(runProccMCurso.url);
      if (data.status !== 200){
        throw new Error('Error al ejecutar SP [dbo].[15_InfVta_MesCurso_Cursor]  en base de Reportes')
       }
      const data_2 = await fetch(fetchGenerateExcelChangePrice.url);
      const json = await data.json();
      const json_2 = await data_2.json();
      setGenerateAumentoBase("cadena");
      setProcessAumentoBaseMsg(json);
      setProcessAumentoBase(false);
    };
    if (processAumentoBase) {
      fetchData()
        .then((x) => {
          return;
        })
        .catch((e) => {
          setMsgError(e?.message || ' Error de Proceso ')
          setProcessAumentoBase(false)
          setGenerateAumentoBase("error");
        });
    }

    return;
  }, [processAumentoBase]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6} sm={6}>
          <Box
            className="animate__animated animate__fadeIn"
            sx={{
              display: "flex",
              backgroundColor: "#E7EBF0",
              padding: "15px",
              justifyContent: "center",
            }}
          >
            <Card sx={{ width: 445, height: "auto" }}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  color={"#ab003c"}
                  className="text-center"
                  component="div"
                >
                  Aumento Base
                </Typography>
                <Typography variant="body2" color="text.primary">
                  Generador de Planillas Excel de Aumento Base
                  <Divider></Divider>
                  <Typography color={"secondary"} variant="subtitle1">
                    {" "}
                    Criterios:{" "}
                  </Typography>
                  <ul>
                    <li>La base de Precios no debe estar bloqueada.</li>
                    <li>El Conteo de Socios es sobre Titulares</li>
                    <li>Se Descartan socios Cuota $0</li>
                    <li>
                      Se Descartan contratos que no tienen modalidad de Debito
                      Automatico ( MOD ID = 3 ) (Semestrales, Anuales,
                      Trimestrales)
                    </li>
                    <li>Se Descarta la sede Corporativo ( 001 )</li>
                  </ul>
                </Typography>
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  size="small"
                  name="cadena"
                  onClick={(e) => handleAumentType(e)}
                  style={{
                    border: "1px solid",
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "#315F21",
                    color: "#fff",
                  }}
                  color="success"
                >
                  Listar Archivos Existentes
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={6} md={6} sm={6}>
         <BaseScreen/>
        </Grid>
      </Grid>

      <hr></hr>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Fab
          variant="extended"
          disabled={processAumentoBase}
          color="secondary"
          onClick={() => {
            handleProcessAumento();
          }}
        >
          {!processAumentoBase && (
            <NavigationIcon sx={{ mr: 1 }} loading variant="outlined" />
          )}
          {processAumentoBase && (
           <p>Cargando...</p>
          )}
          Procesar Nuevamente Aumento Base
        </Fab>
      </Box>
      <hr></hr>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      
        {generateAumentoBase === "cadena" && <Cadena />}
        {generateAumentoBase === "error" && <ErrorAlert message={msgError} />}
        {processAumentoBase && (
          <Box sx={{ width: "600px" }}> {SkeletonSimple()} </Box>
        )}
      </Box>
    </>
  );
};
