import { fetchActiveUsers } from "../../helpers/api_data";
import { useFetch } from "../../hooks/useFetch"




export const ActiveUsers = () => {

    const {url, api_key} = fetchActiveUsers;
    
    const {data, loading, error, errorDetail} = useFetch(url, api_key);

    

  return (

    
    <div>ActiveUsers</div>


  )
}
