import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import '../buscador.css'
import { Chip, Divider, Fab } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { fetchParamCambioEscala } from '../../../../helpers/api_data';
import { formatDDMMYYYYMMHH } from '../../../../helpers/date_converter';
import Swal from 'sweetalert2';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export const CambiarEscala = ({contrato}) => {

    const [escala, setEscala] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [content, setContent] = useState(null)

    
  return (
    <>
<form onSubmit={(e) => {
    
    e.preventDefault();
        if ( contrato && escala ){
        setDisabled(true);
        fetch(`${fetchParamCambioEscala.url}/contraCambioEscala?contrato=${contrato}&nuevaEscala=${escala}`)
        .then(resp => resp.json())
        .then (data => {
            setContent(data[0])
        }).catch(err => {
            
            setContent(null);
            Swal.fire({icon: 'error', title: 'Oops...',
            text: 'Contrato ya posee esa escala / Escala Invalida '})
        })
        .finally(x=>{
            setDisabled(!disabled)
        })
    }
    
}}> 

<div class="centered">
<TextField label={'NuevaEscala'} name='clancy' type="number" id="margin-none" onChange={(e) => {setEscala(Number(e.target.value))}}  />
<button> <Fab size="small" color="success" aria-label="add">
                  <ModeEditOutlineIcon/>
             </Fab> </button>
</div>



</form>



<div class="centered">
    {content &&(
        <>
                <p>Contrato: {content?.Contrato}  |</p>
                <p>EscalaAnterior: {content?.Escala_Anterior}  |</p>
                <p>EscalaNueva: {content?.Escala_Nueva}  |</p>
                <p>EscalaNueva: {'Proceso OK'}  |</p>
                </> 
    )}
</div>

<Divider>
<Chip icon={<ArrowDropDownIcon />} color="primary" label="Resultado" variant="outlined" />
</Divider>

    </>
  )
}
