import React from 'react'

export const InfoJumpLine = ({message}) => {


    return message.split('\n').reduce(function (arr,line) {
        return arr.concat(
          line,
          <br />
        );
      },[]);
}

