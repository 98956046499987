import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cleanSociosReducer } from "../../action/socios";
import { cleanSedeSelected } from "../../action/sedes";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HomeIcon from "@mui/icons-material/Home";
import { setSedesTable } from "../../action/sideBarComponents";

export const ToolBarMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let selectorAuth = useSelector((state) => state.auth);
  const { userPermissions } = selectorAuth;

  return (
    <div
      onClick={(e) => {
        let target = e.target;
        let targetGrandParent = target.parentNode.parentNode;
        let objetive = targetGrandParent.getAttribute("id");
        if (objetive) {
          dispatch(cleanSociosReducer());
          dispatch(cleanSedeSelected());
        }
      }}
    >
      <HomeIcon
        color="primary"
        cursor="pointer"
        sx={{ marginRight: "15px" }}
        onClick={() => navigate("/home")}
      />

      <IconButton
        size="small"
        id="actionSedes"
        onClick={() => {
          dispatch(setSedesTable());
          navigate("/Sedes")


        }}
        color="inherit"
      >
        <Typography variant="body2">Sedes</Typography>
      </IconButton>

      <IconButton
        id="actionSocios"
        onClick={() => navigate("/Socios")}
        color="inherit"
      >
        <Typography variant="body2">Socios</Typography>
      </IconButton>

      <IconButton onClick={() => navigate("/Backup")} color="inherit">
        <Typography variant="body2">Backup</Typography>
      </IconButton>

      <IconButton onClick={() => navigate("/TestFullSedes")} color="inherit">
        <Typography variant="body2">Query</Typography>
      </IconButton>

      <IconButton
        onClick={() => {
          navigate("/Reports");
        }}
        color="inherit"
      >
        <Typography variant="body2">Reportes</Typography>
      </IconButton>

      <IconButton
        onClick={() => {
          navigate("/Servidores");
        }}
        color="inherit"
      >
        <Typography variant="body2">Servidores</Typography>
      </IconButton>

      <IconButton
        onClick={() => {
          navigate("/ApiProclub");
        }}
        color="inherit"
      >
        <Typography variant="body2">Api-Proclub</Typography>
      </IconButton>



      <IconButton
        onClick={() => {
          navigate("/Precios");
        }}
        color="inherit"
      >
        <Typography variant="body2">Precios</Typography>
      </IconButton>
      {userPermissions === "CLIPGODONE" ? (
        <AdminPanelSettingsIcon
          onClick={() => {
            navigate("/AdminPanel");
          }}
          sx={{ marginLeft: "15px", cursor: "pointer" }}
          color="secondary"
        ></AdminPanelSettingsIcon>
      ) : null}
    </div>
  );
};
