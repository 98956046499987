import axios from "axios";
import { useState } from "react";
import { ImDownload3 } from "react-icons/im";
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from "@mui/material";


  export const DownloadFiles = ({url, name='NoDefinido', api_key})  => {
      
    const [loading, setIsLoading] = useState(false)

    const token  = sessionStorage.getItem('token-rp');

    const downloadFiles = (url, name, api_key) => {
      
      setIsLoading(true);
      axios({
        url: url, 
        headers: {
          apikey: api_key,
          'x-token': token,
        },
        method: 'GET',
        responseType: 'blob', 
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false)
    }).catch(e => {
      alert('Error');
      setIsLoading(false)
    }).finally(() => {return  });
    }

 if ( !loading ){
  return(<>
    <a id={'link'+name.toString() }  onClick={() => {downloadFiles(url, name, api_key)}} >
    <ImDownload3 size={25}  style={{color:'blue', cursor:'pointer'}}/>
    </a>  
    </>)
  
 }else{
    return(
      <>
       <Box sx={{ width: '100%' }}>
       <LinearProgress />
       </Box>
      </>
    )

 }
}
