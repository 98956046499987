import React from 'react';
import { useDispatch } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { setComponentAumento, setComponentBuscador, setComponentPreciosDash, setComponentPreciosVenta, setComponentTRN } from '../../../action/sideBarComponents';
import ListIcon  from '@mui/icons-material/List';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

export const ShowPreciosSideIcons = () => {

    const dispatch = useDispatch()

    return (
        <>
            
                <List sx={{padding:'5px'}}>
                
                    <ListItem button key='Dashboard'
                    
                        onClick={() => {
                            dispatch(setComponentPreciosDash());
                        }}>
                        <ListItemIcon>
                            <DashboardIcon
                                color='secondary'
                            />
                        </ListItemIcon>
                        <ListItemText primary='DashBoard'/>
                    </ListItem>

                    <ListItem button key='Precios Venta'
                        onClick={() => {
                            dispatch(setComponentPreciosVenta())
                        }}>
                        <ListItemIcon>
                            <ListIcon
                                color='secondary'
                            />
                        </ListItemIcon>
                        <ListItemText primary='A la Venta' />
                    </ListItem>

                    <ListItem button key='Aumento Base'
                        onClick={() => {
                             dispatch(setComponentAumento())
                        }}>
                        <ListItemIcon>
                            <BackupTableIcon
                                color='#121212'
                            />
                        </ListItemIcon>
                        <ListItemText primary='Aumento Base' />
                    </ListItem>

                    <ListItem button key='Buscador C/E'
                        onClick={() => {
                             dispatch(setComponentBuscador())
                        }}>
                        <ListItemIcon>
                            <BackupTableIcon
                                color='#121212'
                            />
                        </ListItemIcon>
                        <ListItemText primary='Buscador C/E' />
                    </ListItem>

                 



                </List>
           
        </>)
};
