import { types } from "../types/types"


export const setPreciosSidebar = () => ({
    type: types.sidebarPrecios
})

export const setSedesSidebar = () => ({
    type: types.sidebarSedes
})

export const setSociosSidebar = () => ({
    type: types.sidebarSocios
})


