import { types } from "../types/types";

const initialState = {
uid: null,
name: null,
userPermissions: null
}

export const authReducer = ( state =  initialState, action ) => {


    switch ( action.type ) {
        case types.login:
            return {
                ...state,
                uid: action.payload.uid,
                name: action.payload.displayName
            }

            case types.setPermissions:
              return {  ...state,
                userPermissions:action.payload
            }

        case types.logout:
                return { }
    
        default:
            return state;
    }

}