
import { AppRouter } from "./routers/AppRouter"
import { Provider } from 'react-redux'
import { store } from "./store/store"
import React from "react"




export const ReportesApp = () => {

 return(
       <React.StrictMode>
                  <Provider store={store}>
                              <AppRouter/>
                        </Provider>
      </React.StrictMode>
           
            
)}
