import React, {  memo } from 'react'
import { QueryFinally } from './Query'
import { useSelector } from 'react-redux';


export const FechDataSedesPm = memo(() => {

  const sedesSelector = useSelector(sedesSelector => sedesSelector.sedes)
  const {fullListSedes}   = sedesSelector

      return (
        <>
          <hr className="bg-primary"/>
          <div className="mt-3"/>
         
         {fullListSedes&&<QueryFinally data={fullListSedes} ></QueryFinally>}
           </> 
        
        )
})
       