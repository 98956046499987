import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import '../buscador.css'
import { Chip, Divider, Fab } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Swal from 'sweetalert2';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { fetchCambioDescContrato } from '../../../../helpers/api_data'

export const RenombrarContrato = ({ contrato }) => {

    const [desc, setDesc] = useState(null)
    const [disabled, setDisabled] = useState(false)
    const [content, setContent] = useState(null)

    return (<>
            <form onSubmit={(e) => {
                e.preventDefault();
                if (contrato && desc) {
                    setDisabled(true);
                    fetch(`${fetchCambioDescContrato.url}?contrato=${contrato}&nuevaDesc=${desc}`)
                        .then(resp => resp.json())
                        .then(data => {
                            Swal.fire({
                                icon: 'success', title: 'OK',
                                        text: 'Operacion exitosa'
                            })
                        }).catch(err => {
                            setContent(null);
                            Swal.fire({
                                icon: 'error', title: 'Oops...',
                                text: 'Algo salio mal'
                            })
                        })
                        .finally(x => {
                            setDisabled(!disabled)
                        })
                }else{
                    Swal.fire({
                        icon: 'error', title: 'Oops...',
                                text: 'No se ha especificado descripcion'
                    })
                }
            }}>
                <div class="centered">
                    <TextField label={'Nueva Descripcion'} name='clancy' type="text" id="margin-none" onChange={(e) => { setDesc(e.target.value) }} />
                    <button> <Fab size="small" color="success" aria-label="add">
                        <ModeEditOutlineIcon />
                    </Fab> </button>
                </div>
            </form>
            <Divider>
                <Chip icon={<ArrowDropDownIcon />} color="primary" label="Resultado" variant="outlined" />
            </Divider>

        </>
        )
}
