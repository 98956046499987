import React from 'react'
import { ErrorAlert } from '../GlobalMessages/Error'
import { SocioColumnFirst } from './ViewColumns/First/SocioColumnFirst'
import { SociosColumnThree } from './ViewColumns/SociosColumnThree'
import { SociosColumnTwo } from './ViewColumns/SociosColumnTwo'
import { Box, Typography } from '@material-ui/core'
import { Chip, Grid, Divider } from '@mui/material'
import { SociosCtaCte } from './ViewColumns/SociosCtaCte'

export const SociosColumns = ({ status , data,secondData, 
  //terciaryData, pictureResult,
     AMResult, RelationedDebits, debts,debitHistory, activeDebit, auditoria, ctaCte , inactiveDebits, auditAccesoLogs,
     friendProm, suspDebit}) => {

      
      
    if (data){
        const {Nombre, Apellido, DNI, REF, TDocu, SEXO, FIngreso, Frevision, ID } = data;
        
        return (
            <>
                <Divider>
                <Chip label="Informacion Primaria"  color="primary" sx={{  marginTop: 2, marginBottom:2}} />
                </Divider>
             
               {/* THIS IS THE MAIN BOX  */}
               
               <Box sx={{border:`solid 2px ${status.color}`, borderLeft:`solid 15px ${status.color}`,width:'auto', height:'auto',  display:'flex', flexGrow: 1,padding:'0', marginTop:25, marginLeft:'35%', marginRight:'35%', alignContent:'center', justifyContent:'center' }}>
                 <Typography variant='h6'> {`${status.detail}`} </Typography>
                </Box>
              <Box sx={{display:'flex', flexGrow: 1,padding:'15px', marginTop:7, marginLeft:'10%', marginRight:'10%', alignContent:'center', justifyContent:'center' }}>
              
              
                <Grid style={{border:`solid 2px ${status.color}`, borderLeft:`solid 15px ${status.color}`}} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ustifyContent:'center',boxShadow: 3 }}>
                  
                            <Grid item xs={5} sm={8} md={4} key={1} sx={{boxShadow: 0, padding:1, borderRight: '1px dotted', borderColor: 'primary.main'}}    >   
                               <SocioColumnFirst data={{Nombre, Apellido, DNI, TDocu, SEXO, REF, FIngreso, Frevision, ID }}/>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4} key={2} sx={{boxShadow: 0,  padding:1, justifyContent:'center', borderRight: '1px dotted', borderColor: 'primary.main'}}   >   
                               <SociosColumnTwo vigencia={secondData} data={{AMResult, RelationedDebits, debts, friendProm, suspDebit}}  contratoActivo={activeDebit[0]}/>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4} key={3} sx={{justifyContent:'center', boxShadow: 0}}>
                              <SociosColumnThree debitHistory={debitHistory} auditoria={auditoria} auditAccess={auditAccesoLogs}/>
                            </Grid>
                    </Grid>
                    
                    </Box>
                    <Divider>
        <Chip label="Cta Cte"  color="primary" sx={{  marginTop: 2}} />
              </Divider>
              <Box sx={{flexGrow: 1, width:'100%',padding:15, justifyContent:'center' , alignContent:'center'}}>
                {ctaCte&& <SociosCtaCte information={ctaCte}/> }
              </Box>

              

            </>
        )
    }else{
        return(
        <>
       <ErrorAlert message={'Error in Data'}/>
        </>
        )
    }
 
    


    
}
