import React, { useEffect, useState } from "react";
import { postUploadAumento } from "../../../../helpers/api_post_data";
import { fetchPost } from "../../../../helpers/fetchPostFn";
import Gif from '../../../../assets/coffe.gif'
import { DownloadFiles } from "../../../../helpers/downloadFiles";
import { Alert } from "@mui/material";
export const HandleDataToServer = ({ dataToServer }) => {
  const [dataStatus, setData] = useState({
    data: null,
    error: false,
    loading: true,
    errorDetail: null,
  });


  useEffect(() => {
    if (dataToServer) {
      fetchPost(postUploadAumento.url, postUploadAumento.api_key, {
        dataToServer,
      })
        .then((data) =>
          setData({
            data: data,
            loading: false,
            error: false,
            errorDetail: null,
          })
        )
        .catch((err) =>
          setData({ data: null, loading: false, error: true, errorDetail: err })
        );
    }
  }, [dataToServer]);



  if (dataStatus.loading) {
    return (
      <>
        <div>... procesando Aumento Base</div>
        <img src={Gif} alt="loading..." width={100} height={100} />
        <script
          type="text/javascript"
          async
          src="https://tenor.com/embed.js"
        ></script>
      </>
    );
  } else if (dataStatus.data) {

    return (
      <>
      <p>Aumento Exitoso</p>
      <p>Registros de Aumento {dataStatus.data.lineasAumento}</p>
      <Alert icon={false}>Descargar         <DownloadFiles url={dataStatus.data.pathExcel}  name={dataStatus.data.fileName} api_key={postUploadAumento.api_key}  /> </Alert>
     

      </>
    )
  } else if (dataStatus.error) {
    return <>El servidor aun esta procesando, se le notificara via e-mail cuando este finalice</>;
  }
};
