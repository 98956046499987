import React, { useEffect } from 'react'
import { useFetchPost } from '../../hooks/useFetchPost'
import { getSocioData } from '../../helpers/api_post_data'
import { SociosColumns } from './SociosColumns'
import { ErrorAlert } from '../GlobalMessages/Error'
import { isLoading } from '../../action/socios'
import { useDispatch } from 'react-redux'
import { setFinishChange } from '../../action/sedes'
import {  isSuspended, validityCheck } from '../../helpers/date_converter'
import { CreateSocioStatus } from './SocioClass'


export const SociosDatosPrimaryView = React.memo(({ res }) => {

    let body = res;
    const { url, api_key } = getSocioData;
    const { data, error, loading } = useFetchPost(url, api_key, body)
    const dispatch = useDispatch();

    useEffect(() => {

        if (loading) {
            dispatch(isLoading(true));
        } else {
            dispatch(isLoading(false));
            dispatch(setFinishChange());
        }

    }, [loading, res, dispatch]);



    if (data && !error) {

        // THIS IS THE MAIN DATA LINE    
        const { primaryResult, secondaryResult,
            terciaryResult, pictureResult, AMResult,
            RelationedDebits, debts, debitHistory, activeDebit, auditoria, ctaCte, sedeDataActiveDebits, inactiveDebits, auditAccesoLogs,
            friendProm, suspDebit,
             //actualPeriodSystem 
            } = data

        let statusOfSocio = new CreateSocioStatus(validityCheck(secondaryResult[0]?.Vigencia), activeDebit, isSuspended(suspDebit[0]?.SuspendeDesde, suspDebit[0]?.SuspendeHasta))
        let socioStatus = statusOfSocio.returnStatus()


        return (
            <>
                <div className='animate__animated animate__fadeIn slow'>
                    <SociosColumns
                        status={socioStatus}
                        data={primaryResult[0]}
                        secondData={secondaryResult[0]}
                        terciaryData={terciaryResult[0]}
                        pictureResult={pictureResult[0]}
                        AMResult={AMResult[0]}
                        RelationedDebits={RelationedDebits[0]}
                        debts={debts}
                        debitHistory={debitHistory}
                        activeDebit={activeDebit}
                        auditoria={auditoria}
                        ctaCte={ctaCte}
                        sedeDataActiveDebits={sedeDataActiveDebits}
                        inactiveDebits={inactiveDebits}
                        auditAccesoLogs={auditAccesoLogs}
                        friendProm={friendProm}
                        suspDebit={suspDebit[0]}
                    />
                </div>
            </>
        )
    } else if (loading) {

        return (<>
           <p>Cargando</p>
        </>)
    } else if (error) {
        dispatch(isLoading(false))
        return (
            <>
                <ErrorAlert message='Error Searching Socio Data'></ErrorAlert>
            </>
        )
    } else {
        return (
            <>
                <h1>XXX</h1>
            </>
        )
    }

})



