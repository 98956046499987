import moment from 'moment';

export const columnBackup = [
    {
        id: 1,
        DT_RowId: "1",
        title: 'IDSede',
        field: 'IDSede',
        filtering: true,
        width: '11%',
    },
    {
        id: 2,
        DT_RowId: "2",
        title: 'Sede',
        field: 'Sede',
        filtering: true,
        width: '11%',
    },

    {
        id: 3,
        DT_RowId: "3",
        title: 'Fecha Restore',
        field: 'UltimoRestore',
        filtering: true,
        render: rowData => {
            return moment(rowData.UltimoRestore).utc().format('YYYY-MM-DD HH:mm');
          },
    },
    {
        id: 4,
        DT_RowId: "4",
        title: 'Fecha Backup',
        field: 'lastModified',
        filtering: true,
        render: rowData => {
            return   moment(rowData.lastModified).format('YYYY-MM-DD HH:mm');
          },

    },
    {
        id: 5,
        DT_RowId: "5",
        title: 'Sistema',
        field: 'DBName',
        filtering: true,
        render: rowData => {
            let dbNameSpliteo = rowData.DBName.split('_');
            return    (dbNameSpliteo[0] == 'SportClub' ? dbNameSpliteo[0] = 'Jc' : dbNameSpliteo[0] = 'Proclub5');
          },

    },
    {
        id: 6,
        DT_RowId: "6",
        title: 'Tamaño DB',
        field: 'size',
        filtering: true,
        render: rowData => {
            return  (   (((rowData.size/1024)/1024)/1024).toFixed(2) + ' GB'  )
            //return   Number(Math.round(((rowData.size/1024)/1024)/1024))
          },
      

    },
    {
        id: 7,
        DT_RowId: "7",
        title: 'Tamaño Zip',
        field: 'sizeMB',
        filtering: true,
        render: rowData => {
            return  ( rowData.sizeMB + ' MB'  )
            //return   Number(Math.round(((rowData.size/1024)/1024)/1024))
          },
    },
    {
        id: 8,
        DT_RowId: "8",
        title: 'Estado',
        field: 'Estado',
        filtering: true,

    }


]
