import { DashBoardMain } from "./DashBoardMain";
import { useFetch } from "../../hooks/useFetch";
import { fetchSedesParams } from "../../helpers/api_data";
import { populateSedesList } from "../../action/sedes";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export const HomeScreen = () => {
  const dispatch = useDispatch();

  const { url, api_key } = fetchSedesParams;
  const { data } = useFetch(url, api_key);

  useEffect(() => {
    if (data) {
      dispatch(populateSedesList(data));
    }
  }, [data]);

  if (data) {
    return (
      <>
        <DashBoardMain />
      </>
    );
  } else {
    return <p>...</p>;
  }
};
