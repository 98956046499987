import { LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { fetchPreciosVentaLista, fetchRestoreData } from '../../../helpers/api_data';
import { formatDDMMYYYY } from '../../../helpers/date_converter';
import { useFetch } from '../../../hooks/useFetch';
import { TableMaterial } from '../../MaterialTable/GlobalTableMaterial';
import { columns } from './PreciosListaVentaColumns';

export const PreciosListaVenta = () => {

    const [showCorporate , setShowCorporate] = useState()
    const [dataProc, setDataProc ] = useState([])

      const { url, api_key } = fetchPreciosVentaLista;
    const { data, loading, error } = useFetch(url, api_key)
  

    useEffect(() => {
      
        if ( data ){
                let s = data.filter( x => x.IDSede!==1);
                let ss =  s.sort(function(a, b) {
                   return b.IDSede - a.IDSede;
                  });
                setDataProc(ss)
        }

    }, [data])
    
        
    useEffect(() => {
        if ( showCorporate ){
             
        }
    }, [showCorporate])

const handleCorporateData = ( res ) => {
    setShowCorporate(res);
}
    
   
const rowPanelPrecios = ({rowData}) => { 
           return (
             <div
               style={{
                 fontSize: 20,
                 textAlign: "left",
                 height: '100%',
               }}
             >
                 <ul>
                       <li>
                        <strong>Nombre Escala:</strong> { rowData.EscalaNombre   }
                        </li>
                        <li>
                        <strong>Vigencia:</strong> { formatDDMMYYYY(rowData.Vigencia,'-') === '01-01-1970' ? 'Sin Vencimiento' : formatDDMMYYYY(rowData.Vigencia,'-')   }
                        </li>
                        <li>
                        <strong>BajaAutomatica:  </strong>  {rowData.BajaAutomatica?'SI':'NO'}  
                        </li>
                        <li>
                        <strong>ID_Escala_GED:  </strong>    {rowData.ID_Escala_GED}  
                        </li>
                        <li>
                        <strong>TC: </strong>   {rowData.VipTarjeta?'SI':'NO'} |  <strong>FTVO</strong>   {rowData.VipEfectivo?'SI':'NO'} 
                        </li>
                 </ul>
              
             </div>
               )
}

    const renderTable = (rowPanelPrecios, columns) => {
        return ( <>
        <button onClick={()=>{ handleCorporateData(!showCorporate) }} >Incluir Corporate </button>
    <TableMaterial
        data={dataProc}
        columns={columns}
        title='Contratos a la Venta[Sistema de Precios]'
        rowPanel={rowPanelPrecios}
    />
</> )}

    if (data && dataProc) {   
        return (  renderTable(rowPanelPrecios, columns) )
    } else if (loading) {
        return (
            <LinearProgress />
        )
    } else if (error) {
        return (
            <h1>Error</h1>
        )
    }
}
