export const fetchPost = async(url, api_key, body) => {

    const token  = sessionStorage.getItem('token-rp') || '';

    const a = await fetch(url,{
        
            method: 'POST', 
            mode: 'cors',
            timeout: 6000, 
            headers: {
              'Content-Type': 'application/json',
              'apikey': api_key,
              'x-token':token
            }, 
            body: JSON.stringify(body)
    })
    const b = await a.json();
    return b;

}

