import React from "react";
import { apiKeyValue, url } from "../../../../helpers/api_data";
import { useState } from "react";

import perfil from "../../../../assets/apiproclub/perfil.png";
import image from "../../../../assets/apiproclub/image.png";

import "./styleProclubSearch.css";
import UpdateSocio from "../update/UpdateSocio";

export const ApiProclubSearch = () => {
  const [documentoState, setDocumento] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataFromApi, setDataFromApi] = useState(null);
  const [isUpdate, setIsupdate] = useState(false);


  const handleSubmit = async (e) => {
    
    e.preventDefault();
    setLoading(true);

    const token  = sessionStorage.getItem('token-rp');

    try {
      const response = await fetch(
        url + "apiProclub" + "/documento/" + documentoState,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            'apikey': apiKeyValue,
            'x-token':token,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      setDataFromApi(result);
    } catch (err) {
      setDataFromApi(null);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };



  return (
    <>
      <div>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/sweetalert2@11.7.20/dist/sweetalert2.min.css"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="style.css" />
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n  body{\n    font-family: 'Montserrat', sans-serif;\n}\n.buttonClass{\n    display: block;\n    height: 50px;\n    color: red;\n    margin-bottom: 50px;\n}\n\n\n#error{\n    color:rgb(0, 0, 0);\n    background-color: red;\n    display: flex;\n    flex-wrap: wrap; \n    justify-content: center;\n}\n\n\n.datosApi{\n\n    border-style: double;\n    font-size: small;\n    margin:1%;\n    text-align: center;\n    width: 14rem;\n    font-weight: 500;\n    border:2px solid black\n    \n}\n\n#imgT{\n\n\n    clip-path: circle(45.0% at 50% 50%);\n}\n\n#infMembresia ,#infContacto,#infAdicional{\n    display: none;\n}\n\n.nombre{\n    font-family: montse;\n}\n\n@keyframes fadeIn {\n    0% {\n      opacity: 0;\n      transform: translateY(-10px);\n    }\n    100% {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }\n",
          }}
        />
        <div className="container mx-auto py-8">
          <h1 className="text-2xl font-bold mb-4">Api ProClub</h1>
          <h1 className="text-xl font-bold mb-2">Realizar búsqueda por:</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="p-4 bg-white rounded shadow">
              <p>Documento</p>
              <form onSubmit={handleSubmit}>
              <input
                type="number"
                id="inputDni"
                onChange={(e) => setDocumento(e.target.value)}
                className="mt-2 bg-gray-300"
              />
               </form>
            </div>
          </div>
          <button
            id="btn"
            type="submit"
            className="mt-6 px-4 py-2 bg-blue-500 text-white rounded shadow mb-4"
            onClick={handleSubmit}
          >
            Obtener datos
          </button>

          <button
            id="btn"
            type="submit"
            className="mt-6 px-5 py-2 ml-3 bg-green-500 text-white rounded shadow mb-4"
            onClick={() => setIsupdate(!isUpdate)}
          >
            Editar
          </button>

          {isUpdate&& <UpdateSocio dataFromApi={dataFromApi} handleSubmitSocio={handleSubmit}  />}
          
          {loading ? (
            <>
              <p>Cargando...</p>
            </>
          ) : (
            <>
              {dataFromApi && (
                <>
                  <div id="error" className="mt-8" />
                  <div id="tarjetaSocio" className="bg-gray-500 ">
                    <div className="navTarjetaSocio flex   bg-black">
                      <div className="flex-1 text-center text-white">
                        <img src={image} alt="" srcSet />
                      </div>
                    </div>
                    <div className="flex">
                      <div className="text-black flex-3">
                        <div class="ml-4 mt-4">
                          Nombre: {dataFromApi.Nombre}
                        </div>

                        <div
                          id="Apellido"
                          className="text-white text-6xl ml-8"
                        />
                        <div class="ml-4 mt-4">
                          Apellido: {dataFromApi.Apellido}
                        </div>
                        <img
                          id="imgT"
                          className="w-64 mt-8"
                          src={perfil}
                          alt=""
                          srcSet
                        />
                      </div>
                      <div className="test">
                        {Object.keys(dataFromApi).map((key) => (
                          <li key={key}>
                           <b> {key} </b> : {dataFromApi[key]}
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
