import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { TableMaterial } from '../../MaterialTable/GlobalTableMaterial';
import { formatDDMMYYYYMMHH } from '../../../helpers/date_converter';
import { Grid } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height: 'auto',
  bgcolor: '#CCD0D5',
  color:'#C38FFF',
  borderRadius: 4, 
  boxShadow: 54,
  p: 3,
};

export const ModalAccesoLog = React.memo(({information}) => {


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

const columns = [
    {  
id: 1,
DT_RowId: "1",
title:'Fecha',
field: 'FechaYHora',
filtering: true,
width: 'auto',
render: rowData => {
    return  formatDDMMYYYYMMHH(rowData.FechaYHora,'-')
  },
  headerStyle: {  
  }
},
{  
    id: 2,
    DT_RowId: "2",
title:'TablaAccess',
field: 'TablaAccess',
width: 'auto'
},{  
    id: 3,
    DT_RowId: "4",
title:'Parametros',
field: 'Parametros',

},
{
    id: 4,
    DT_RowId: "4",
title:'Error',
field: 'Error',
}]



  if (information){
    return (<div>
          <Button onClick={handleOpen}>VER DETALLE</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid sx={style}>
            <TableMaterial data={information} columns={columns} title={'Auditoria ControlAccesos'}></TableMaterial>
            </Grid>
          </Modal>
        </div>
      );   
}else{
    return(
        <h6>Sin Informacion</h6>
            
        )
}


  
})