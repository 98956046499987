import { useState, useEffect } from 'react';

export const useFetchPostMegaQuery =  ( url, api_key, body ) => {  

    const token  = sessionStorage.getItem('token-rp');



    const [state, setState] = useState({ data: null, loading: true, error: null, errorDetail:null });  
    useEffect( () => {
        setState({ data: null, loading: true, error: null, errorDetail:null });     
         fetch( url,{
            method: 'POST',
            mode: 'cors',
            timeout: 6000,    
            headers: {
              'Content-Type': 'application/json',
              'apikey': api_key,
              'x-token':token,
            },
            body: JSON.stringify(body) 
            })
            .then( resp => resp.json() )
            .then( data => {            
                    setState({
                        data,
                        loading: false,
                        error: null,
                        errorDetail: null       
                    });  
            }).catch( (e) => {
                setState({
                    data:null,
                    loading: false,
                    error: true,
                    errorDetail: e
                });
              });          
    },[ api_key, url])
    return state;
}

