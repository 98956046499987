import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { columnsDebitH } from './SocioDebitHColumns';
import { TableMaterial } from '../../MaterialTable/GlobalTableMaterial'
import moment from 'moment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height: 'auto',
  bgcolor: '#CCD0D5',
  color:'#C38FFF',
  borderRadius: 4, 
  boxShadow: 54,
  p: 3,
};

export default function SocioBasicModal({information}) {


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (information){

    for (const iterator of information) {
     let a = iterator.PeriodoGenerado
  
     let b = new Date(a)
     
     iterator.PeriodoGenerado=moment(b).format('YYYY/MM')
    }

    

  

    return (
        <div>
          <Button onClick={handleOpen}>Ver Detalle</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Historico Debitos
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div style={{ height: '100%', width: '100%' }}>
              <TableMaterial data={information} columns={columnsDebitH} title={'Historico Debitos Proclub'} ></TableMaterial>         
              </div>
          
              </Typography>
            </Box>
          </Modal>
        </div>
      );   
}else{
    return(
        <h6>Sin Informacion</h6>
            
        )
}


  
}


/*
<DataGrid
getRowId={(r) => r.Row}
rows={information}
columns={columnsDebitH}
pageSize={20}
components={{
  Toolbar: GridToolbar,
}}
rowsPerPageOptions={[20]}
disableSelectionOnClick
/>

*/