import React from 'react'
import { ApiProclubSearch } from './components/search/ApiProclubSearch'
import UpdateSede from './components/updatesede/UpdateSede'

export const ApiProclubScreen = () => {
  return (
    <>
    <UpdateSede/>
    <hr/>
    <ApiProclubSearch/>

    </>
  )
}
