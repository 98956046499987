
import { Routes, Route, Navigate } from "react-router-dom";
import { SedesScreen } from "../components/Sedes/SedesScreen";
import { SociosScreen } from "../components/Socios/SociosScreen.js";
import { DaxeScreen } from "../components/Reports/Debits/DaxeScreen.js";
import { PreciosScreen } from "../components/Precios/PreciosScreen.js";
import { useSelector } from "react-redux";
import { NoPermission } from "../components/NoPermission.js";
import { FullQueryScreen } from "../components/FullQuery/FullQueryScreen.js";
import { AdminPanelScreen } from "../components/AdminPanel/AdminPanelScreen.js";
import { BackupScreen } from "../components/Backup/BackupScreen.js";
import { HomeScreen } from "../components/Home/HomeScreen";
import { ServidoresScreen } from "../components/servidores/ServidoresScreen";
import { ApiProclubScreen } from "../components/apiproclub/ApiProclubScreen";



export const DashboardRoutes = () => {

    const authState = useSelector(authState => authState.auth)


    const {userPermissions} = authState;

        return (     
            <>
            
                 <Routes>    
                        <Route path="/" element={<Navigate replace to="/home" />} />                        
                        <Route path="/home"  element={<HomeScreen />} />   
                        <Route path="/Sedes"  element={ (userPermissions==='CLIPGODONE' || userPermissions?.includes('SED'))?(<SedesScreen />):(<HomeScreen />)}  />
                                                <Route path="/Socios" element={ (userPermissions==='CLIPGODONE' || userPermissions?.includes('SOC'))?(<SociosScreen />):(<HomeScreen />)} />
                        <Route path="/Servidores" element={ (userPermissions==='CLIPGODONE' || userPermissions?.includes('SOC'))?(<ServidoresScreen />):(<HomeScreen />)} />
                        <Route path="/ApiProclub" element={ (userPermissions==='CLIPGODONE' || userPermissions?.includes('SOC'))?(<ApiProclubScreen />):(<HomeScreen />)} />
                        <Route path="/TestFullSedes" element={ (userPermissions==='CLIPGODONE' || userPermissions?.includes('MQUERY'))?(<FullQueryScreen />):(<HomeScreen />)} /> 
                        <Route path="/Reports"  element={ (userPermissions==='CLIPGODONE' || userPermissions?.includes('DXE'))?(<DaxeScreen />):(<HomeScreen />)}/>
                        <Route path="/Precios"  element={ (userPermissions==='CLIPGODONE' || userPermissions?.includes('PRE'))?(<PreciosScreen />):(<NoPermission />)} />
                        
                        <Route path="/AdminPanel"  element={ (userPermissions==='CLIPGODONE')?(<AdminPanelScreen/>):(<NoPermission />)} />
                        <Route path="/Backup"  element={ (userPermissions==='CLIPGODONE')?(<BackupScreen/>):(<NoPermission />)} />
                </Routes>
                
             </>
        )
    
}

