import React, { memo } from 'react'
import { SociosDatosPrimaryView } from './SociosDatosPrimaryView';


export const SociosDatosPrimary = memo(({IDSede, Codigo, DNS, Port, System}) => {
    
    let body={
        IDSede, Codigo, DNS, Port, System
    }

    if ( IDSede && Codigo && DNS && Port && System ){
        return (
            <>       
                <SociosDatosPrimaryView res={body}/>         
            </>
        )

    }else{
        return(
            <>
            <h6>Err</h6>
            </>
        )
    }
  
   
   
})
