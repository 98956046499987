import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SocioModalDebtsTable } from './SocioModalDebtsTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  heigth: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 10,
  border: 'radius 0px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SocioModalDebts({ information }) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let totalDebts = information.map(x => x.Importe).reduce((a, b) => a + b);


  return (
    <div>
      <Button onClick={handleOpen}>


        <Typography variant='button'  > VER DETALLE </Typography>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" color={'#d81b60'} component="h2">
              Total Rechazos: ${totalDebts}
            </Typography>
            <SocioModalDebtsTable data={information} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
