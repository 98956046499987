import React from "react";
import { useDispatch } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ListItemButton from "@mui/material/ListItemButton";
import { addSede } from "../../../action/sideBarComponents";

export const ShowSedesSideIcons = () => {

    const dispatch = useDispatch()


  return (
    <>
      <List sx={{ padding: "5px" }}>
        <ListItem
          disablePadding
          key="Agregar"
          onClick={() => {
            dispatch(addSede());
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <AddIcon sx={{ color: "#9C27B0" }} />
            </ListItemIcon>
            <ListItemText primary="Agregar" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          button
          key="Edit"
          onClick={() => {
            alert("Completar Modulo Editar Sedes");
          }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </ListItem>
      </List>
    </>
  );
};
