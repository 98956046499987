import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { formatDDMMYYYY, YYYYMM } from '../../../helpers/date_converter';
import { Divider } from '@mui/material';

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  heigth: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 3,
  border: 'radius 0px solid #000',

  boxShadow: 24,
  p: 5,
};

export default function SocioActiveDebitModal({information}) {


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {Alta, Au_Modalidad, Categoria, ContratoAu, 
    CtoBase, Cuota, Descripcion, Escala,
EscalaAislada, NRelacion, Periodo, SocioCondicion, 
Tarjeta, Valor, bajaAutomaticaEn} = information


  return (
    <div>
      <Button variant='primary' style={{fontSize:'15px',background:'lightblue', marginBottom:'15px', width:'auto', height:'auto' }} onClick={handleOpen}>
       {Descripcion} (${Valor})
       
          </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
        

                <React.Fragment>
                    <CardContent>
                    <Typography variant="h5" component="div">
                   {bull}Alta: {formatDDMMYYYY(Alta, '-')}
                   
                    </Typography>


                    <Typography variant="body2" style={{marginTop:'5px'}}>
                    {bull} Modalidad:{Au_Modalidad}
                        <br />
                        {bull} Categoria:{Categoria}
                        <br />
                        {bull}  ID:{ContratoAu}
                        <br />
                        {bull}  Cuota:{Cuota} 
                        <br />
                        {bull}  NRelacion:{NRelacion}
                        <br />
                        {bull}   Periodo:{YYYYMM(Periodo,'-')}
                        <br />
                        {bull}   Condicion:{SocioCondicion}
                        <br />
                        {bull}   Tarjeta:{Tarjeta}
                        <br />
                        {bull}   BajaAutom:{bajaAutomaticaEn===0?('Cuota de Baja no Definida'):(' En cuota Nº: '+bajaAutomaticaEn)}
                    </Typography>
                    <Divider></Divider>
                    <Typography variant="body1" style={{marginTop:'5px'}}>
                     Escala:{Escala}   |   Ctobase:{CtoBase}
                     <br />
                     EscalaAisladaProclub: {EscalaAislada}
                    </Typography>
                    </CardContent>
                    <CardActions style={{background:'lightblue', width:'100%', height:'5px'}}>
                    
                    </CardActions>
                </React.Fragment>

          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
