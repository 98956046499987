import React, {  useEffect } from 'react'
import { getSocioByDni } from '../../../helpers/api_post_data';
import { useFetchPost } from '../../../hooks/useFetchPost';
import { ErrorAlert } from '../../GlobalMessages/Error';
import SearchSocioBasicCard from './SocioSearchCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useDispatch, useSelector } from 'react-redux';
import { setSocio } from '../../../action/socios';

export const SocioSearchApiRun = React.memo(({body}) => {

    const { url, api_key } = getSocioByDni;
    const {data, loading} = useFetchPost(url, api_key, body)

  

    const dispatch = useDispatch();

    const state = useSelector(state => state.sedes)
    const so = useSelector(so => so.socios)
    const {Codigo} = so
    
    let {sedeSelected, fullListSedes} = state

        const x = fullListSedes.find(x=>x.IDSede===Number(sedeSelected))
        
        const {DNS,Port,System} = x ;
        let dataToBackend = {
            "IDSede": Number(sedeSelected),
            "Codigo": Number(Codigo),
             "DNI": null,
            "DNS": DNS,
            "Port": Port,
            "System": System,
            "loaded": true,
            "modal": false,
          }
            
          useEffect(() => {
              
                 dispatch(setSocio(dataToBackend))
           
        }, [dispatch])    


 
        if (data === 'Err'){
            return(
                <div className='mt-1'>
                    <span >
                    <ErrorOutlineIcon/>
                    No Se han encontrado datos para su ingreso.
                    </span>
                </div>
            )
        }  else if ( data ){
      
            const listItems = data.map((x) =>
            <SearchSocioBasicCard data={x}/> );
        
            return (
                <>
                        {listItems}
                </>
            )
        }
      else if ( loading ){
        return ( <> ..Cargando  </>)
    }else{
        return(
        <ErrorAlert message={'Error al buscar socio'}></ErrorAlert>
        )
    }
  })
