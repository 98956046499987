import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { formatDDMMYYYY } from '../../../helpers/date_converter';
import { useDispatch } from 'react-redux'
import { clearDniSocio, setSocioBySearch, socioToScreen } from '../../../action/socios';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function SearchSocioBasicCard({data}) {

  
    const {Apellido, Codigo, Fingreso, Ndocu, Nombre, Tipo} = data ;
    const dispatch = useDispatch()
    
  const handleSearchSocio =  ( Codigo )=> {    
    dispatch(setSocioBySearch(Codigo))
    dispatch(socioToScreen())
    dispatch(clearDniSocio())
  
  }

  return (
      <>
     
     <Card sx={{ minWidth: 100, marginLeft:1, borderLeft:'solid 4px coral' }}>
      <CardContent>
        <Typography sx={{ fontSize: 1 }} color="text.secondary" gutterBottom>
          Socio Encontrado
        </Typography>
        <Typography variant="h5" component="div">
        {bull}DNI:   {Ndocu}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
         {Tipo}
        </Typography>
        <Typography variant="body2">
          {Nombre} { Apellido }
          <br />
          REF#: {Codigo}
          <br />
          Ingreso: {formatDDMMYYYY(`${Fingreso}`, '-')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={()=> {handleSearchSocio(Codigo)}}>Cargar Este Socio</Button>
      </CardActions>
    </Card>

       
   
 
    </>
  );
}
