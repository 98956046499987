import { types } from "../types/types";

export const setSocio = ( data ) => ({
    type: types.socioSetData,
    payload: data
});

export const changeSede = (data) =>({
    type: types.sociosChangeSede,
    payload: data
});

export const setSocioLoadedFalse = () =>({
    type: types.sociosLoadedFalse
    
});

export const setSocioBySearch = (data) =>({
    type: types.socioChangeBySearch,
    payload: data
    
});

export const setDniSocio = (data) =>({
    type: types.socioSetDNI,
    payload: data
}); 

export const clearDniSocio = () =>({
    type: types.clearDNI
}); 

export const sociosCloseModal = () =>({
    type: types.socioCloseModal 
});

export const sociosOpenModal = () =>({
    type: types.socioOpenModal  
});

export const socioToScreen = () =>({
    type: types.socioToScren  
});

export const socioUnset = () =>({
    type: types.socioUnsetScreen  
});

export const cleanSociosReducer = () =>({
    type: types.clean  
});

export const isLoading = (data) =>({
    type: types.sociosLoading,
    payload: data  

});



