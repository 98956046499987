
export const types = {

    login: '[Auth] Login',
    logout: '[Auth] Logout',
    setPermissions: '[Auth] Set Permissions',


    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',
    

    sidebarDefault: 'ShowDefault',
    sidebarSedes: 'ShowSedes',
    sidebarPrecios: 'ShowPrecios',
    sidebarReportes: 'ShowReports',
    sidebarSocios:'ShowSocios',

    setListComponent: 'PreciosVenta',
    setPreciosDashboard: 'PreciosDashboard',
    setAumentoBase: 'AumentoBaseComponent',
    setBuscador: 'BuscadorComponent',
    setTRN: 'TrnComponent',
    setListDaxe: 'ListDaxeComponent',
    clearComponent: 'ClearComponent',
    setLoad: 'Load On',
    unsetLoad: 'Load Off',

    setSedeSelected: 'SedeSelected',
    populateSedeList: 'Populate',
    setChange: 'Set Change True',
    setFinishChange: 'Set Finish Change',
    sedeSelectedClean:'Clean Sede Selected',

    socioSetData: 'SocioSetData',
    sociosChangeSede: 'ChangeSede',
    sociosLoadedFalse: 'LoadedFalse',
    socioChangeBySearch: 'ChangeRefSocio',
    socioSetDNI:'Set Dni Socio',
    clearDNI:'Clear DNI',
    socioCloseModal: 'CloseModal',
    socioOpenModal: 'OpenModal',
    socioToScren: 'Set Socio to Screen',
    socioUnsetScreen: 'Unset Socio to Screen',
    sociosLoading: 'Start Load of Data',
    clean: 'Full Clean',
    
    addsede: 'Add Sede',
    sedes: 'Sedes'
    

}


