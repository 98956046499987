import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import  imgAvatar from '../../../../assets/avatar.jpg';
import { Container, Grid } from '@mui/material';
import { formatDDMMYYYY } from '../../../../helpers/date_converter';


export const SocioColumnFirst = ({data}) => {


  const {Nombre, Apellido, DNI, TDocu, SEXO, REF, FIngreso, ID, Frevision} = data;
    
  const fnValidateSex = (SEX) => {
    if ( SEX.trim() === 'F'){
      return 'Mujer'
    }else{
      return 'Hombre'
    }
}

const fnValidateTDocu = (TDOCU) => {
if ( TDOCU === 4){
  return 'DNI'
}else{
  return 'OTRO'
}
}
    return (
        <>
       
       <Grid container className='' spacing={0} sx={{display:'flex', flexDirection:'row',   justifyContent:'center', padding:0}}>
       <Container fluid sx={{display:'flex',justifyContent:'center'}}>
      <Avatar  src={imgAvatar} sx={{width:'50px', height:'50px'}}  />
      </Container>
       <Grid item sx={{ justifyContent:'center', width:'50%', alignContent:'left', padding:0 }}>
     
       <List >
         
      <ListItem sx={{maxWidth:'230px', maxHeight:'72px'}}>
     
        <ListItemText
          primary="Nombre:"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {Nombre} {Apellido}
              </Typography>
             
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider  />
      <ListItem alignItems="flex-start">
      
        <ListItemText
        
          primary="Doc:"
          
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {fnValidateTDocu(TDocu)} {DNI}
              </Typography>
              
            </React.Fragment>
          }
        />
      </ListItem>

      <Divider  />
      <ListItem alignItems="flex-start">
       
        <ListItemText
          primary="Sexo"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {fnValidateSex(SEXO)}
              </Typography>
           
            </React.Fragment>
          }
        />
      </ListItem>

      <Divider  />
      <ListItem alignItems="flex-start">
       
        <ListItemText
        
          primary="REF #:"
          
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {REF}
              </Typography>
              
            </React.Fragment>
          }
        />
      </ListItem>



      
    </List>

        </Grid>
        <Grid item sx={{marginLeft:0, justifyContent:'right', width:'50%', alignContent:'right'}} >
        <List >
                    <ListItem >
                    
                      <ListItemText
                        primary="F.Ingreso:"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {formatDDMMYYYY(FIngreso,'-')} 
                            </Typography>
                          
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider  />
                    <ListItem >
                     
                      <ListItemText
                        primary="-"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              - 
                            </Typography>
                          
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider />
                    <ListItem >
                     
                      <ListItemText
                        primary="F.Rev:"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {formatDDMMYYYY(Frevision,'-')} 
                            </Typography>
                          
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider />
                    <ListItem >
                     
                      <ListItemText
                        primary="REF #PRC:"
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline', justifyContent:'center', textAlign:'center' }}
                              component="span"
                              variant="body2"
                              color="black"
                            >
                              {ID} 
                            </Typography>
                          
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                   
                
                  </List>
        </Grid>


      </Grid>

          
        </>
    )
}
