import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Divider,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { HandleExcelData } from "./HandleExcelData";

export const BaseScreen = () => {
  const [load, setLoad] = useState(false);
const  [dataExcel, setDataExcel] = useState(null);




  const uploadFile = (e) => {
   
    e.preventDefault();
    setLoad(!load);
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary', cellDates: true});
        
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
      
        if ( json ){
            setDataExcel(json);
            setLoad(false);
        }
          setLoad(false);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
  
    }
  };


  return (
    <>
      <Box
        className="animate__animated animate__fadeIn"
        sx={{
          display: "flex",
          backgroundColor: "#E7EBF0",
          padding: "15px",
          justifyContent: "center",
        }}
      >
        <Card sx={{ width: 445, height: "auto" }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              color={"#ab003c"}
              className="text-center"
              component="div"
            >
              Aumento Base Proceso
            </Typography>
            <Typography variant="body2" color="text.primary">
              Generador de Aumento Base Automatico
              <Divider></Divider>
            </Typography>
          </CardContent>
        
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" component="label" disabled={load}>
              Subir ARchivo
              <input
                type="file"
                name="excelAumento"
                id="upload"
                hidden
                onChange={uploadFile}
              />
            </Button>
          
          </CardActions>
          <Divider></Divider>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          
            
            {dataExcel&&<HandleExcelData data={dataExcel} />}
            </CardActions>
        </Card>
      </Box>
    </>
  );
};
