import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Divider, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { SocioSearch } from './SocioSearch';
import Swal from 'sweetalert2';
import { clearDniSocio, setDniSocio, sociosCloseModal, sociosOpenModal, socioUnset } from '../../../action/socios';
import { useEffect } from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 2,
  border: 'none',
  boxShadow: 24,
  p: 4,
};

export default function SocioSearchModal({disabled}) {



  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dniChange, setDniChange] = React.useState(null)
  const [startSearch, setStartSearch] = React.useState(false)
  const [textDisable, setTextDisable] = React.useState(false)

const {toScreen} = useSelector(toScreen => toScreen.socios)


    useEffect(() => {
      if ( open ) {
        
        dispatch(sociosOpenModal())
      }else{
        dispatch(clearDniSocio())
        dispatch(sociosCloseModal())
      }
      if (toScreen){
        handleClose()

        setTimeout(() => {
          dispatch(socioUnset())
          
        }, 1500);
      }


       
    }, [dispatch, open, toScreen])



  const handleInputChange = (e) => {
     let dataCaptured =  e.target.value


      if (dataCaptured){
        setDniChange(dataCaptured)
        dispatch(setDniSocio(dataCaptured))
       
      }
    
  }

  const goToSearch = (dniChange)=> {
    
      if (dniChange){
        setStartSearch(true)
        setTextDisable(true)
      }else{
          Swal.fire('Err')
      }
    
  }

  let sedeData; 
  const state = useSelector(state => state.sedes)
  const {sedeSelected} = state


  if (sedeSelected ){
    sedeData = state.fullListSedes.find(x=>x.IDSede===Number(sedeSelected))
  }


  if (sedeSelected &&  sedeSelected!=='Pick a Sede' ){

   

    return (
        <>
        <Button variant="outlined" disabled={disabled} className='mt-3' onClick={handleOpen}> <PersonSearchIcon/> {`Socio en Sede:${sedeSelected}`}</Button>
          
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
              >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" sx={{padding:1, justifyContent:'center', color:'secondary', alignContent:'center', textAlign:'center'}} component="h2">
                {`Busqueda de Socio por DNI en  Sede: ${sedeSelected}, ${sedeData.Sede}`}
              </Typography>
              <Divider></Divider>
              
              <div style={{ height: 'auto', width: 'auto', padding:5 }}>
                
                <Box sx={{display:'flex',padding:3, flexDirection:'space', justifyContent:'center', width:400}} >
                    
              <TextField 
              required={true}
              disabled={textDisable}
              id="standard-basic-dni" label="Ingrese DNI"  
              inputProps={{ maxLength: 8 }} 
              onChange={handleInputChange}
              value={dniChange}
              name='dni'
               type='number' variant="standard"  ></TextField>
              <SearchIcon sx={{marginTop:2}}></SearchIcon>

              <Button variant="outlined"
               disabled={textDisable}
              onClick={goToSearch}
              >Go</Button>

    <Divider orientation="vertical" flexItem/>
      
  

               <Button variant="outlined"
            
              onClick={ 
                  ()=>{
                      if ( textDisable ){
                        setDniChange(null)
                            setTextDisable(false)
                                setStartSearch(false)
                                let s = document.getElementById('standard-basic-dni')
                              
                                s.value=''
                      }else{
                        setStartSearch(false)
                        setDniChange(null)
                      }
                  }
              }
              
              >Buscar Otro</Button>

              </Box>
                <Box sx={{ display:'flex', flexDirection:'row', width:'auto', height:'auto', flexWrap: 'nowrap' }}>
                 <Divider/>
                {startSearch&&<SocioSearch dni={dniChange} sedeData={sedeData}/>} 
                </Box>
              
                </div>

              
            </Box>
          </Modal>
        </>
      );   
  }else{
      return(
          <>
          
          </>
      )
  }
    

}