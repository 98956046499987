import React, { useState, useEffect } from 'react'
import { clearComponent, setListDaxeComponent, setLoadOn } from '../../../action/sideBarComponents';
import { useDispatch } from 'react-redux';
import { Timer } from '@material-ui/icons';

export const DaxeInterval = () => {
  

    const [timer, setTimer] = useState(180);
    const dispatch = useDispatch();


    
  
    useEffect(() => {
        
        dispatch(setLoadOn());

        if ( timer === 5){
            dispatch( clearComponent());
        }

        timer === 0&& ( dispatch( setListDaxeComponent())  );
    
        if (timer !== 0){

            const interval = setInterval(() => {
                setTimer(timer => timer - 1);
            }, 1000);
            return () => clearInterval(interval);

        }
      
    },[timer, dispatch]);
  
    return ( timer )


}
  


