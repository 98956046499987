import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPreciosSidebar } from "../../action/sideBar";
import Typography from "@mui/material/Typography";
import { PreciosDashBoard } from "./DashBoard/PreciosDashBoard";
import { AumentoBaseScreen } from "./Aumento/AumentoBaseScreen";
import { PreciosListaVenta } from "./Venta/PreciosListaVenta";
import { BuscadorScreen } from "./buscador/BuscadorScreen";
import { TrnScreen } from "./trnComponent/TrnScreen";

export const PreciosScreen = () => {
  const dispatch = useDispatch();
  const { mainComponent, display } = useSelector((state) => state.component);
  const [state, setState] = useState([mainComponent, display]);

  useEffect(() => {
    setState([mainComponent, display]);
  }, [mainComponent, display]);

  useEffect(() => {
    if (!display) {
      setState(["Precios", "DashBoard"]);
    }
  }, []);

  useEffect(() => {
    dispatch(setPreciosSidebar());
  });

  if (state[0] === "Precios" && state[1] === "List") {
    return (
      <div className="animate__animated animate__fadeIn slow">
        <Typography variant="h6" component="div" gutterBottom></Typography>

        <PreciosListaVenta />
      </div>
    );
  } else if (state[0] === "Precios" && state[1] === "DashBoard") {
    return <PreciosDashBoard />;
  } else if (state[0] === "Precios" && state[1] === "AumentoBase") {
    return <AumentoBaseScreen />;
  } else if (state[0] === "Precios" && state[1] === "Buscador") {
    return <BuscadorScreen />;
  } else if (state[0] === "Precios" && state[1] === "TRN") {
    return <TrnScreen />;
  } else {
    return <></>;
  }
};
