import React from 'react'
import { fetchDaList } from '../../../helpers/api_data'
import { useFetch } from '../../../hooks/useFetch'
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';

import { ErrorAlert } from '../../GlobalMessages/Error';
import { Box } from '@mui/system';
import { DownloadFiles } from '../../../helpers/downloadFiles';



export const DaxeListFiles = () => {
  const { urlDaxeList, api_key } = fetchDaList;
  const { data, loading, error } = useFetch(urlDaxeList, api_key);
  

  let dataOrderById = []
  if (data) {
    for (let i = Number(data.length) - 1; i >= 0; i--) {
      const element = data[i];
      dataOrderById.push(element)
    }
  }

  

  const columns = [
    {
      DT_RowId: "1",
      field: 'fileDate',
      headerName: 'Fecha',
      flex: 1,
      minWidth: 150,
      textAlign: "center",
      align:'center',
      headerClassName: 'super-app-theme--header',
     
    },
    {
      DT_RowId: "2",
      field: 'name',
      headerName: 'Archivo',
      flex: 1,
      minWidth: 150,
      align:'center',
      headerClassName: 'super-app-theme--header',
     
    },
    {
      DT_RowId: "3",
      field: "Descargar",
      flex: 1,
      minWidth: 150,
      align:'center',
      headerClassName: 'super-app-theme--header',
    

      
      renderCell: (params) => {

        const { row } = params;
        const { url, name, id } = row;
          return (<><DownloadFiles url={url} name={name} api_key={api_key}  />    </>)
      }
    }
  ]
  if (loading) {
    return (
      <>
        <Box sx={{ display: 'flex' }} className='animate__animated animate__headShake'>
          <Typography>  Cargando ... </Typography>
          <p>...Cargando</p>
        </Box>
      </>
    )
  } else if (error) {
    return (
      <ErrorAlert message='Error al listar Archivos' />
    )
  }
  else if (dataOrderById) {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          autoHeight={true}
          autoWidth={true}
          getRowId={(r) => r.ID}
          rows={dataOrderById}
          columns={columns}
          disableExtendRowFullWidth={true}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          sx={{
            '& .super-app-theme--header': {
              backgroundColor: 'rgba(255, 7, 0, 0.55)',
            },
            boxShadow: 2,
            border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
        />
      </div>
    )
  }
}
