import { getAuth, signInWithPopup,  signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { googleAuthProvider } from '../firebase/firebaseConfig';

import { types } from '../types/types';
import { finishLoading, startLoading, setError } from './ui';

 
export const startLoginEmailPassword = (email, password) => {
    return (dispatch) => {
        const auth = getAuth();
        dispatch(startLoading())
        signInWithEmailAndPassword(auth, email, password)    
            .then(({user}) => {
                
                dispatch(login( user.uid, user.displayName ))
                dispatch(finishLoading());
            })
            .catch((err) => {
        dispatch(setError(err))
          dispatch(finishLoading());
            })
                
                
            
    };
};
 /*
export const startRegisterWithEmailPasswordName = (email, password, name) => {
    return (dispatch) => {
 
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then(async ({ user }) => {
 
                await updateProfile(user, { displayName: name });
 
                dispatch(login(user.uid, user.displayName));
            })
            .catch(e => {
              
            });
    }
};
 */
export const startGoogleLogin = () => {
    return (dispatch) => {
        const auth = getAuth();
        signInWithPopup(auth, googleAuthProvider)
       
            .then(({ user }) => {
                dispatch(login(user.uid, user.displayName));
                
            });
    };
};
 
export const login = (uid, displayName) => ({
    type: types.login,
    payload: {
        uid,
        displayName
    }
});

export const readPermissions = (data) => ({
    type: types.setPermissions,
    payload: data
})

export const logoutAction = () => {
    return async(dispatch) => {
        const auth = getAuth();
        await signOut(auth);
        dispatch(logout());
    }
}

export const logout = () => ({
    type: types.logout
})