import { Alert, Box,Button, Switch } from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'

import Swal from 'sweetalert2'
import { postSedesToRestore } from '../../helpers/api_post_data'
import { TableMaterial } from '../MaterialTable/GlobalTableMaterial'
import { columnBackup } from './BackupColumn'
import { BackupTable } from './BackupTable'

export const BackupScreen = () => {

  const [dataBackup, setDataBackup] = useState('')
  
  useEffect(() => {
    
    const backupData = async () => {
      const resp = await
          fetch('https://apiprices.sportclub.com.ar:2000/api/backup/backupStatus')
      const data = await resp.json()
      return data;
  }

  const restoreData = async () => {
      const resp = await
          fetch('https://apiprices.sportclub.com.ar:2000/api/backup/restoreData')
      const data = await resp.json()
      return data;
  }
  
    const fullData = async () => {
      try {
          let r = await Promise.all([
              restoreData(),
              backupData()])
                return r
          }
      catch (err) {
          console.warn(err);
      };
  }
  
  fullData()
  .then(res => setDataBackup(res))
  }, [])
  
  const postInfo = (data,data2) => {
        
    let arr = [];
    
    for (let index = 0; index < data.length; index++) {
      let o = {};
      let select = data[index]
      const { IDSede: id, Sede: nameSede, UltimoRestore: restore, DBName: dbName, 
        BackupSize: size } = select
 
      let backUpData = data2.find(x => x.IDSede === id)
      
      let aBackup = moment((backUpData?.lastModified ? backUpData?.lastModified : 'No existe sede')).format('YYYY-MM-DD HH:mm')
      let check = moment(aBackup).isSame(moment(), 'day')
      let bRestore = moment(restore).utc().format('YYYY-MM-DD HH:mm')
      
    const handleCheck = (check, aBackup, bRestore) => {
        if (!check){
          return 'DAYDIFF'
        }else if(moment(bRestore).isBefore(aBackup)){
          return 'NORESTORED'
        }
        return 'OK'
    }
      o["IDSede"]=id
      o["Sede"]=nameSede
      o["UltimoRestore"]=restore
      o["DBName"]=dbName
      o["fileLocation"]=backUpData.fileLocation;
      o["fileName"]=backUpData.fileName;
      o["lastModified"]=backUpData.lastModified;
      o["size"]=Number(size);
      o["sizeMB"]=backUpData.sizeMB;
      o["Estado"]=handleCheck(check,aBackup, bRestore);     
      arr.push(o);
    }
 
    if ( arr.length > 0){
      const handleRowCondition = (rowdata) => {
          if ( rowdata?.Estado === 'NORESTORED'){
            return {backgroundColor: "yellow"}
          } else if (rowdata?.Estado === 'DAYDIFF' ){
            return {backgroundColor: "red"}
          }
      }

      const rowPanelEmpty = () => { 
        return null }

      const handleRestore = (data)=>{
     
        
        const sedesToRestore = data.filter(x => x.Estado==='NORESTORED')
        const sedesIdToRestore = sedesToRestore.map ( x => x.IDSede)
        const sedesNombreToRestore = sedesToRestore.map ( x => x.Sede)
        const sedesDBName = sedesToRestore.map ( x => x.DBName)



        if ( sedesToRestore.length === 0 ){
        
        return   Swal.fire('No hay sedes para restaurar')
        }        

        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        
        swalWithBootstrapButtons.fire({
          title: 'Confirma?',
          html: `<strong>A Restaurar:</strong> ${sedesNombreToRestore.join()}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes ',
          cancelButtonText: 'No ',
          reverseButtons: true
        }).then(async (result) => {
          if (result.isConfirmed) {
            const handleRequest = async (IDSede, DBName) => {
               let a  = await fetch(postSedesToRestore.url,{
                 method:'POST',
                 mode: 'cors',
                  headers: {
                    'Content-Type': 'application/json',
                    'apikey': postSedesToRestore.api_key,
                  },               
                 body:JSON.stringify( {"IDSede": Number(IDSede),
                "Sistema":DBName  } )
               });
               let b = await a.json();
               return b
            }
            let arrResult = [];
            for (let i = 0; i < sedesIdToRestore.length; i++) {
              const idSedeToSend = sedesIdToRestore[i];
              const dbNameToSend = sedesDBName[i];
              let c = await handleRequest(idSedeToSend, dbNameToSend);
              arrResult.push(c);
            }

          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
           Swal.close()
          }
        })
      }

     
      return(
        <>       
        <BackupTable handleRestore={handleRestore}
         data={arr}

         columnBackup={columnBackup} 
          rowPanelEmpty={rowPanelEmpty} 
          handleRowCondition={handleRowCondition} >
          </BackupTable>  
       </>
      )
    }else{
      <>
      No Data
      </>
    }
  }
 
  return (
    <Box className='animate__animated animate__fadeIn' sx={{}}>
      
      {dataBackup&&(postInfo(dataBackup[0],dataBackup[1]))}
      {!dataBackup&&( <> <p>Cargando...</p> </>  )}
    </Box>
    
  )
}
