import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { Alert, Box, TextField } from '@mui/material';
import './sedes.css'

export const SedeSelector = ({handleSelector}) => {
  
    const state = useSelector(state => state.sedes)
    const sedes = state?.fullListSedes;
    const sedesProclub = sedes?.filter(x => x.System===1)
    
    const [sedesList, setSedesList] = useState('')
    const [sedeSelected, setSedeSelected] = useState(0)
    const [sedeFiltrada, setSedeFiltrada] = useState(null)

    
    useEffect(() => {
     if ( sedes ){
     
        if ( sedeFiltrada ){
            let newr = sedesProclub?.filter(x => x.Sede.includes(sedeFiltrada))
            setSedesList(newr)
        }else{
            let onlyProclub = sedes?.filter(x => x.System===1)
            setSedesList(onlyProclub)
        }
       

       
     }else{

     }
    }, [sedes, sedeFiltrada])

    useEffect(() => {

      if ( sedeSelected > 0){
        let sedeConnData  = sedesProclub.find(x => x.IDSede === sedeSelected)
        handleSelector(sedeSelected, sedeConnData);
      }
    
    }, [sedeSelected])

    useEffect(() => {

      setSedeSelected(157)
    }, [])
    

    


      if ( sedesList ){
        return (
            <Box className='boxResponsive'>
            <TextField id="standard-basic" label="Filtrar por Nombre" variant="standard" color='primary'  className='mb-3' autoComplete='off'
            
            
            onChange={(e) => {
              
                let value = e.target.value
                value=value.toUpperCase() 
                if ( value.length >= 1){
                    setSedeFiltrada(value);
                }else if ( value.length===0){
                    setSedeFiltrada(null);
                }
                if (sedesList[0] ){
                  setSedeSelected(sedesList[0].IDSede)
                }
                ;
            } } />
          <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 500 }}>
            <InputLabel shrink htmlFor="select-multiple-native">
            Listado de Sedes
            </InputLabel>
            <Select
              simple
              native
              value={sedeSelected}
              onChange={(e) => {setSedeSelected(Number(e.target.value)) }}
              label="Listado de Sedes"
              inputProps={{
                id: 'select-multiple-native',
              }}>        
              {sedesList.map((sedes) => (
                <option key={sedes.IDSede} value={sedes.IDSede}>
                  {sedes.IDSede} {sedes.Sede.trim()}
                </option>
              ))}
            </Select>
          </FormControl>
          <Alert variant="outlined" size="small" severity='info' sx={{m: 1, maxWidth: 500, padding:0.5}}>Sede Escogida: {sedeSelected}</Alert>
        </Box>
        )
    }else {
        return ( <p>No se Cargo el Listado de Sedes</p> )
    }
   
}
