import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { FechDataSedesPm } from './FechDataSedesPm';

export const FullQueryScreen = () => {
    const [start, setStart] = React.useState(false);
    return (<>
        <Box sx={{ backgroundColor: '', width: 'auto', height: 'auto' }}>
            <Button variant="contained" color="primary" style={{ border: '2px solid', padding: '2px' }}
                onClick={() => {
                    !start ? (setStart(true)) : (setStart(false))
                }}
            > {!start ? ('Cargar Mega-Query') : ('Ocultar Mega-Query')}
            </Button>
            {start && (<FechDataSedesPm />)}
        </Box>

    </>)
};
