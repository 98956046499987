import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { InfoComponent } from './infcomponent/InfoComponent'
import { ProccessComponent } from './infcomponent/ProccessComponent'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SendIcon from '@mui/icons-material/Send';

import './ActionScreen.css'

export const ActionScreen =({dataConn}) => {

    const [action, setAction] = useState(null)
    const [show, setShow] = useState(false)

  useEffect(() => {
      if ( dataConn && action ){
        setShow(true)
      }else{
        setShow(false)
      }

  }, [dataConn ,action])
  
  useEffect(() => {
  if ( action){
    setAction(null)
  }

}, [dataConn])

  

  const handleAction = (actionArg) => {
    setAction(actionArg);
    }  


    const renderServerAction = () => {
if (action !== "info"){
      return (
        <ProccessComponent action={action} sedeConn={dataConn}/>
          )
    }
    else if (action === "info"  ){
     return(<><InfoComponent action={action} sedeConn={dataConn}/></>) 
    }else{
      return(
        <>
        Sin datos para mostrar
        </>
      )
    }
    }

    return (
      <>
  <Box className='bigbox'>
  <Button onClick={() => (handleAction("backup"))} color='warning' name='backup'  size="small" variant="contained" endIcon={<SendIcon />}>Backup</Button>
   <Button onClick={() =>(handleAction("envbackup"))}  color='warning' variant="outlined" name='enviarbackup'  ><FiberManualRecordIcon/>EnviarBackup</Button>
    
    
    <Button onClick={() =>(handleAction("updtserver"))}color='error'  variant="outlined" name='updtserver'  ><FiberManualRecordIcon/>Update Node Server</Button>
  </Box>
  <Box className='bigbox'>
  
  <Button onClick={() =>(handleAction("info"))} color='primary' variant="outlined" name='info'  ><FiberManualRecordIcon/>Info</Button>
  <Button onClick={() =>(handleAction("srvversion"))} color='primary' variant="outlined" name='srvversion'  ><FiberManualRecordIcon/>Server Version</Button>
    <Button onClick={() =>(handleAction("checkTrn"))} color='primary' variant="outlined" name='checkTrn'  ><FiberManualRecordIcon/>Check TRN</Button>
    <Button onClick={() =>(handleAction("runTrn"))} color='primary' variant="outlined" name='runTrn'  ><FiberManualRecordIcon/>Run TRN</Button>
    </Box>
    
    

  <Box className='bigbox'>
  <Button onClick={() =>(handleAction("procEnvDatos"))}color='secondary'  variant="outlined" name='procEnvDatos'  ><FiberManualRecordIcon/>Sede Envio</Button>
    <Button onClick={() =>(handleAction("procRcpDatos"))}color='secondary'  variant="outlined" name='procRcpDatos'  ><FiberManualRecordIcon/>Sede Recepcion</Button>
    <Button onClick={() =>(handleAction("checkEnv"))}color='secondary'  variant="outlined" name='checkEnv'  ><FiberManualRecordIcon/>Check Envio Datos</Button>
    <Button onClick={() =>(handleAction("checkRcp"))}color='secondary'  variant="outlined" name='checkRcp'  ><FiberManualRecordIcon/>Check Recepcion Datos</Button>
    </Box>

{show && renderServerAction()}
</>
)
  
}
