import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useNavigate } from 'react-router-dom'
import { DashboardRoutes } from '../../routers/DashboardRoutes';
import { ToolBarMain } from './ToolBar';
import { DefaultSideList } from './SideBarList/Default';
import AccountMenu from './SideBarList/MenuList';

const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function ResponsiveDrawer() {

  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);




  return (
    <Box sx={{ display: 'flex', backgroundColor: '#CCD0D5', height: '100vh', width: '100vw' }}>
      <CssBaseline />
      <AppBar position="fixed" style={{ background: '#093170', fontFamily: 'Roboto', alignContent: 'center', justifyContent: 'center' }} open={open}>
        <Toolbar variant="dense">

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {setOpen(true)}}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <ToolBarMain />

        </Toolbar>
      </AppBar>
      <Drawer

        sx={{

          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: '#153f59',
            padding: 0,
            //backgroundImage: `url(${imgbackCafe})`,
            //backgroundSize: 'cover',
            //backgroundPosition: 'center',
            boxShadow: '-5px 7px 53px -10px #fff',
            color: '#fff'

          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >

        <DrawerHeader>


          <IconButton onClick={() => {setOpen(!open)}}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>

        </DrawerHeader>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '15px', marginBottom: '15px' }}>
          <AccountMenu
            cursor="pointer"
          />
        </Box>
        <Divider />

        <DefaultSideList />

      </Drawer>
      <Main open={open}>
        <DrawerHeader />

        <Box style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '10px', width: 'auto', height: 'auto' }} sx={{ boxShadow: 3 }}>
          <DashboardRoutes />
        </Box>

      </Main>
    </Box>
  );

}
