
import React from "react";
import MaterialTable from "@material-table/core";
import { downloadExcel } from "../../hooks/useExcelExport";
import { ErrorAlert } from "../GlobalMessages/Error";


export const TableMaterial = React.memo(({data,columns,title, rowPanel, rowCondition,isLoading, maxBodyHeight}) => {
  
  

  if ( data && columns) {

    return (
  
      <MaterialTable  
        title={title}
        columns={columns}
        data={data}
       dense     

       detailPanel={
        rowPanel?((rowData) => { return  rowPanel(rowData)}):false}
        options={{   
          idSynonym: 'unique_parent_id',
           detailPanelType: "multiple" ,
          columnsButton: true,
          filtering: true,
          paginationType: "normal",
          paginationPosition: "top",
          headerStyle: { 
            position: 'sticky',
            fontSize: '1rem',
            padding:'0px',
            justifyContent:'center',
            alignContent:'center',
            textAlign:'center',       
             top: 1,  
              backgroundColor:
               "#01579b",
            color: "#FFF", },
          maxBodyHeight: maxBodyHeight?maxBodyHeight:'auto',
          columnsHiddenInColumnsButton: true,
          rowStyle: (rowdata) => {
            if ( rowCondition ){
              return rowCondition(rowdata)
            }else
            return {padding:'0',
            fontSize: '0.844rem',}
          },
          padding:'dense',
          grouping: true,
          pageSize:50,
          pageSizeOptions: [15,50,100],
          paging: true,
          //maxBodyHeight: '300px',
          fixedHeader:true,

          exportMenu: [   
          {
            label: 'Bajar a Excel',
            exportFunc: () => downloadExcel(data)
          }]
        }}  
      />

   );
  }else{
    return(
      <ErrorAlert message='Error al cargar Tabla'/>
    )
  }

 

 
});
