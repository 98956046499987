import 'firebase/firestore';
import 'firebase/auth';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { GoogleAuthProvider } from 'firebase/auth';
//import { collection, addDoc } from "firebase/firestore";
//import { getUserPermissions } from './getUserPermissions';


const firebaseConfig = {
  apiKey: "AIzaSyCmf77sIWbUeM21xL4wjbnGuo95aS6WyA0",
  authDomain: "reportesnodeauth.firebaseapp.com",
  projectId: "reportesnodeauth",
  storageBucket: "reportesnodeauth.appspot.com",
  messagingSenderId: "716831054339",
  appId: "1:716831054339:web:f5d12642f1a8537b5d4c36"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();

const googleAuthProvider = new GoogleAuthProvider();
 


export{
    db,
    googleAuthProvider
}