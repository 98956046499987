import React, { useState } from 'react'
import { apiKeyValue, url } from '../../../../helpers/api_data';
import { SedeSelector } from '../../../selector/SedeSelector'
import { Alert, Box, Button, Typography, } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
const UpdateSede = () => {
  const token = sessionStorage.getItem("token-rp");
    
    const [loading, setLoading] = useState(false);
    const [dataFromApi, setDataFromApi] = useState(null);
    const [errorData, setErrorData] = useState(null);
    const [dataConn, setDataConn] = useState(null);
    
 

const handleSelector = (sede, sedeFiltrada) => {
  setErrorData(null)
    setDataConn(sedeFiltrada);
   }

const handleUpdateSede = async (e) => {
  setDataFromApi(null)
  setErrorData(null)
    e.preventDefault();
 setLoading(true);
 
    try {
      const response = await fetch(
        url + "apiProclub" + "/update/" + dataConn.IDSede,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            apikey: apiKeyValue,
            "x-token": token,
          },
        }
      );
      const result = await response.json();
      setDataFromApi(result);
    } catch (err) {
      setErrorData('Error al actualizar Sede' + err)
    } finally {
        setLoading(false);
    }
  };
   



  return (
   <>
      <Box sx={{padding:2}}>
      <h3 className="text-2xl font-bold mb-4">Actualizar Sede Proclub</h3>
       <SedeSelector handleSelector={handleSelector}/>
       {loading&&<p>Cargando...</p>}
       {dataFromApi&&(<>
       <Box sx={{backgroundColor:'#e8eaf6'
       ,marginLeft:5
       ,display:'flex'
       ,flexDirection:'column'
       ,maxWidth:500
       }}>
        {dataFromApi?.code=='500'&&<ReportProblemOutlinedIcon sx={{marginLeft:5, color:'red'}}/>}
        <Typography variant="overline" sx={{marginLeft:5}}><b>Codigo:</b> {dataFromApi?.code}</Typography>
        <Typography  variant="overline" sx={{marginLeft:5}}> <b>Mensaje Api Proclub:</b> {dataFromApi?.message}</Typography>
        </Box>
        </>)}
       {errorData?<Alert  sx={{marginLeft:5}} severity="error" >{errorData}</Alert>:<></>}
        <Button sx={{marginLeft:5, marginTop:1}} variant="contained" color='secondary' onClick={handleUpdateSede}> Actualizar </Button>
    
    </Box>
   </>
  )
}

export default UpdateSede