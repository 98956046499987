import moment from 'moment';
import React from 'react'
import { Button, Card } from 'react-bootstrap';

import SocioBasicModal from './SocioModal';
import SocioModalDebts from './SocioModalDebts';
import { ButtonBase, Grid, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import SocioActiveDebitModal from './SocioActiveDebitModal';
import { isSuspended, YYYYMM } from './../../../helpers/date_converter'


export const SociosColumnTwo = ({ data, contratoActivo, vigencia }) => {

     let AMDate;
     const { Vigencia } = vigencia
     const { AMResult, RelationedDebits, debts, friendProm, suspDebit } = data;
     let tempDate = moment(Vigencia).format('DD-MM-YYYY');

    if (AMResult) {
        AMDate = moment(AMResult.ValidoHasta).utc().format('DD-MM-YYYY');
    }
    const vigenciaValidator = (x) => {
        const dateIsBefore = moment(x).isAfter(moment())
        if (dateIsBefore) {
            return "success"
        } else {
            return "danger"
        }
    };
    const showSuspCard = () => {
      
     
        let suspCheck  = isSuspended(suspDebit?.SuspendeHasta, suspDebit?.SuspendeHasta)
       
          
        if (suspDebit && (suspDebit.SuspendeDesde !== "1900-01-01T00:00:00.000Z" && suspDebit.SuspendeHasta !== "1900-01-01T00:00:00.000Z")) {
            return (
                <Grid item>
                    <Card style={{ width: '155px', height: '100px', padding: 0, marginTop: 5, justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                        <Card.Body>
                            <Typography><strong> {suspCheck?('Suspension Vigente'):('Suspension No Vigente')}    </strong> </Typography>
                            <ButtonBase style={{ color: '#1769aa' }} onClick={() => {
                                Swal.fire({
                                    icon: 'info',
                                    title: `Supensión Programada(Período)`,
                                    html: `Desde: ${YYYYMM(suspDebit.SuspendeDesde, '/')}  Hasta:  ${YYYYMM(suspDebit.SuspendeHasta, '/')}` 
                                        + '<br></br>' +
                                        `[Meses sin Cobro] ${YYYYMM(suspDebit.SuspendeDesde, '-', 1)} Hasta ${YYYYMM(suspDebit.SuspendeHasta, '-', 1)}`,
                                    footer: `Retorna actividad: ${YYYYMM(suspDebit.SuspendeHasta, '-', 2)}`
                                })
                            }}> VER DETALLE</ButtonBase>
                        </Card.Body>
                    </Card>
                </Grid>)
        } else return (<></>)
    }

    
    return (
        <>
            <Grid container sx={{ justifyContent: 'center' }}>
                {contratoActivo && (
                    <SocioActiveDebitModal information={contratoActivo} />
                )}
            </Grid>

            <Grid container className='' spacing={1} sx={{ justifyContent: 'center' }}>
                <Grid item  >
                    <Card style={{ width: '155px', height: '100px', padding: 0, marginTop: 5, justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                        <Card.Body>
                            <Card.Title>Vigencia</Card.Title>
                            <Button variant={vigenciaValidator(Vigencia)}

                            >{tempDate === '31-12-1999' ? ('Sin Fecha') : tempDate}</Button>
                        </Card.Body>
                    </Card>
                </Grid >
                <Grid item  >
                    <Card style={{ width: '155px', height: '100px', padding: 0, marginTop: 5, justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}
                        onClick={() => {
                            Swal.fire({
                                icon: 'info',
                                title: `Apto Medico`,
                                html: `Cargado: ${moment(AMResult?.Cargado).utc().format('DD-MM-YYYY')}` +
                                    ` por: <strong> ${AMResult?.UsuarioCargaApto ? (AMResult?.UsuarioCargaApto) : ('<p>[Dato No Registrado]</p>')}</strong>`
                                    + `<p className="mt-2">Certificado en Base: ${AMResult?.Certificado}</p>`,
                                text: `Certificado en Base: ${AMResult?.Certificado}`,
                                footer: `Presentado en: ${AMResult?.SedePresentado}, valido hasta: ${moment(AMResult?.ValidoHasta).utc().format('DD-MM-YYYY')}`
                            })
                        }}
                    >
                        <Card.Body>
                            <Card.Title>Apto Medico</Card.Title>
                            {AMDate && (<Button variant={vigenciaValidator(AMResult.ValidoHasta)}>{AMDate === '31-12-1999' ? ('Sin Fecha') : AMDate}</Button>)}
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid item >
                    <Card style={{ width: '155px', height: '100px', padding: 0, marginTop: 5, justifyContent: 'center', alignContent: 'center', textAlign: 'center' }}>
                        <Card.Body>
                            <Card.Title>Debito Relacionado</Card.Title>
                            <SocioBasicModal
                                information={RelationedDebits}
                            />
                        </Card.Body>
                    </Card>
                </Grid>
                <Grid item>
                    <Card style={debts[0] ? ({ width: '155px', height: '100px', border: '0.5px solid red', padding: 0, marginTop: 5, justifyContent: 'center', alignContent: 'center', textAlign: 'center' }) : ({ width: '155px', height: '100px', padding: 0, marginTop: 5, justifyContent: 'center', alignContent: 'center', textAlign: 'center' })}>
                        <Card.Body>
                            <Card.Title>{debts[0] ? ('Deudas') : 'Sin Deudas'}</Card.Title>
                            {debts[0] && <SocioModalDebts information={debts} />}
                        </Card.Body>
                    </Card>
                </Grid>
                {friendProm[0] && (<Grid item>
                    <Card style={debts[0] ? ({ width: '155px', height: '100px', border: '0.5px solid red', padding: 0, marginTop: 5, justifyContent: 'center', alignContent: 'center', textAlign: 'center' }) : ({ width: '155px', height: '100px', padding: 0, marginTop: 5, justifyContent: 'center', alignContent: 'center', textAlign: 'center' })}>
                        <Card.Body>
                            <Typography><strong>Promo Amigo</strong> </Typography>
                            <Button variant='primary' onClick={() => {
                                Swal.fire({
                                    icon: 'info',
                                    title: `Generada por: Ref# ${friendProm[0].Codigo}`,
                                    text: `Dni: ${friendProm[0].NDocu}, Nombre:${friendProm[0].Nombre} ${friendProm[0].Apellido} `,
                                    footer: 'Periodo afectado: Proxima'
                                })
                            }}>Detalle </Button>
                        </Card.Body>
                    </Card>
                </Grid>)}
                {showSuspCard()}
            </Grid>
        </>
    )
}
