import { types } from "../types/types"

const initialState =
{
    IDSede: null,
    Codigo: null, 
    DNS: null,
    Port: null,
    System: null,
    loaded: false,
    modal: false,
    toScreen: false,
    loading: false,
    DNI: 0,
}

export const sociosReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.socioSetData:          
            return { 
                ...state,
                ...action.payload }
            

        case types.sociosChangeSede:
            return ({
                ...state,
                Codigo: null
            })

        case types.sociosLoadedFalse:
            return ({
                ...state,
                loaded: false
            })

        case types.socioChangeBySearch:
            return ({
                ...state,
                Codigo: action.payload
            })

        case types.socioSetDNI:
            return ({
                ...state,
               DNI: action.payload
            })

        case types.clearDNI:
            return ({
                ...state,
                DNI: null
            })


        case types.socioCloseModal:
            return ({
                ...state,
                modal: false
            })

        case types.socioOpenModal:
            return ({
                ...state,
                modal: true
            })

        case types.socioToScren:
            return ({
                ...state,
                toScreen: true
            })

        case types.socioUnsetScreen:
            return ({
                ...state,
                toScreen: false
            })

        case types.clean:
            return ({
                ...initialState
            })

        case types.sociosLoading:
            return ({
                ...state,
                loading: action.payload
            })

        default:
            return state;
    }

}